.Card {
    width: 670px;
    height: 838px;
    position: relative;
    overflow: hidden;
    border-radius: 0px;
    /* box-shadow: 0 20px 40px rgba(0,0,0, 0.25);
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1) */
    /* animation: HeroAnimation;
    animation-duration: 3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1); */
}

.Card:hover img {
    /* transform: translateY(-20px); */
    opacity: 0.5;
}


.Card img {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: -1;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}

.Card2 {
    color: rgb(255, 255, 255);
    margin-top: 0px;
    font-weight: 600px;
    font-size: 34px;
    line-height: 40px;
    font-weight: 600;
    width: 302px;
    margin-left: 60px;
}




.Card1 {
    color: rgba(255,255,255, 0.8);
    text-transform: uppercase;
    font-weight: 300;
    font-size: 14px;
    align-self: top;
    margin-top: 60px;
    margin-left: 60px;
    margin-bottom: 10px;
    letter-spacing: 3px;

}



.Card3 {
    color: rgba(255,255,255, 1);
    font-weight: 400;
    font-size: 16px;
    align-self: top;
    margin-top: 20px;
    margin-left: 60px;
    margin-bottom: 10px;
    opacity: 0;

}

.Card:hover .Card3 {
    /* color: rgba(255,255,255, 1); */
    animation: HeroAnimation;
    animation-duration: 3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
}

.Card:hover .Card1 {
    /* color: rgba(255,255,255, 0.8); */
    animation: HeroAnimation;
    animation-duration: 3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
}

.Card:hover .Card2 {
    /* color: rgba(255,255,255, 1); */
    animation: HeroAnimation;
    animation-duration: 3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
}


@media (max-width: 1699px) {
   .Card {
        width: 500px;
        height: 625px;
        position: relative;
        overflow: hidden;
    }
    .Card2 {
        color: rgb(255, 255, 255);
        margin-top: 0px;
        font-size: 30px;
        line-height: 36px;
        font-weight: 600;
        width: 302px;
        margin-left: 30px;
    }
    .Card1 {
        font-size: 14px;
        margin-top: 30px;
        margin-left: 30px;
        margin-bottom: 6px;
        letter-spacing: 3px;
    
    }
    .Card3 {
        font-size: 16px;
        margin-top: 20px;
        margin-left: 30px;
        margin-bottom: 10px;
    }
  }

  @media (max-width: 1400px) {
    .Card {
         width: 425px;
         height: 530px;
         position: relative;
         overflow: hidden;
     }
     .Card2 {
         color: rgb(255, 255, 255);
         margin-top: 0px;
         font-size: 24px;
         line-height: 30px;
         font-weight: 600;
         width: 280px;
         margin-left: 30px;
     }
     .Card1 {
         font-size: 14px;
         margin-top: 30px;
         margin-left: 30px;
         margin-bottom: 6px;
         letter-spacing: 3px;
     
     }
     .Card3 {
         font-size: 16px;
         margin-top: 15px;
         margin-left: 30px;
         margin-bottom: 10px;
     }
   }

   @media (max-width: 1199px) {
    .Card {
         width: 378px;
         height: 472px;
         position: relative;
         overflow: hidden;
     }
     .Card2 {
         color: rgb(255, 255, 255);
         margin-top: 0px;
         font-size: 24px;
         line-height: 30px;
         font-weight: 600;
         width: 280px;
         margin-left: 30px;
     }
     .Card1 {
         font-size: 14px;
         margin-top: 30px;
         margin-left: 30px;
         margin-bottom: 6px;
         letter-spacing: 3px;
     
     }
     .Card3 {
         font-size: 16px;
         margin-top: 15px;
         margin-left: 30px;
         margin-bottom: 10px;
     }
   }
   @media (max-width: 969px) {
    .Card {
         width: 360px;
         height: 450px;
         position: relative;
         overflow: hidden;
     }
     .Card2 {
         color: rgb(255, 255, 255);
         margin-top: 0px;
         font-size: 24px;
         line-height: 30px;
         font-weight: 600;
         width: 280px;
         margin-left: 20px;
     }
     .Card1 {
         font-size: 14px;
         margin-top: 20px;
         margin-left: 20px;
         margin-bottom: 6px;
         letter-spacing: 3px;
     
     }
     .Card3 {
         font-size: 16px;
         margin-top: 15px;
         margin-left: 20px;
         margin-bottom: 10px;
     }
   }

   @media (max-width: 819px) {
    .Card {
         width: 350px;
         height: 450px;
         position: relative;
         overflow: hidden;
     }
     .Card2 {
         color: rgb(255, 255, 255);
         margin-top: 0px;
         font-size: 24px;
         line-height: 30px;
         font-weight: 600;
         width: 280px;
         margin-left: 20px;
     }
     .Card1 {
         font-size: 14px;
         margin-top: 20px;
         margin-left: 20px;
         margin-bottom: 6px;
         letter-spacing: 3px;
     
     }
     .Card3 {
         font-size: 16px;
         margin-top: 15px;
         margin-left: 20px;
         margin-bottom: 10px;
     }
   }
   /* only screen and (min-width: 749px) and  */
   @media (max-width: 749px) {
    .Card img {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
    }
    .Card {
         width: 100%;
         /* max-height: 700px;
         height: 50%; */
         height: 100vw;
         /* max-width: 560px;
         max-height: 700px; */
         /* min-width: 600px; */
         /* min-height: 100%; */
         overflow: hidden;
         position: relative;
         object-fit: cover;
     }
     .Card2 {
         color: rgb(255, 255, 255);
         margin-top: 0px;
         font-size: 24px;
         line-height: 30px;
         font-weight: 600;
         width: 280px;
         margin-left: 20px;
         margin-right: 0px;
     }
     .Card1 {
         font-size: 14px;
         margin-top: 20px;
         margin-left: 20px;
         margin-bottom: 6px;
         letter-spacing: 3px;
         width: 280px;
     }
     .Card3 {
         font-size: 16px;
         margin-top: 15px;
         margin-left: 20px;
         margin-bottom: 10px;
         width: 280px;
     }
   }

   @media (max-width: 599px) {
    .Card img {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
    }
    .Card {
         width: 100%;
         /* max-height: 700px;
         height: 50%; */
         height: 120vw;
         /* max-width: 560px;
         max-height: 700px; */
         /* min-width: 600px; */
         /* min-height: 100%; */
         overflow: hidden;
         position: relative;
         object-fit: cover;
     }
     .Card2 {
         color: rgb(255, 255, 255);
         margin-top: 0px;
         font-size: 24px;
         line-height: 30px;
         font-weight: 600;
         width: 280px;
         margin-left: 20px;
         margin-right: 0px;
     }
     .Card1 {
         font-size: 14px;
         margin-top: 20px;
         margin-left: 20px;
         margin-bottom: 6px;
         letter-spacing: 3px;
         width: 280px;
     }
     .Card3 {
         font-size: 16px;
         margin-top: 15px;
         margin-left: 20px;
         margin-bottom: 10px;
         width: 280px;
     }
   }

@keyframes HeroAnimation {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }


  @media (max-width: 359px) {
    .Card img {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
    }
    .Card {
         width: 100%;
         /* max-height: 700px;
         height: 50%; */
         height: 120vw;
         /* max-width: 560px;
         max-height: 700px; */
         /* min-width: 600px; */
         /* min-height: 100%; */
         overflow: hidden;
         position: relative;
         object-fit: cover;
     }
     .Card2 {
         color: rgb(255, 255, 255);
         margin-top: 0px;
         font-size: 24px;
         line-height: 30px;
         font-weight: 600;
         width: 260px;
         margin-left: 20px;
         margin-right: 0px;
     }
     .Card1 {
         font-size: 14px;
         margin-top: 20px;
         margin-left: 20px;
         margin-bottom: 6px;
         letter-spacing: 3px;
         width: 260px;
     }
     .Card3 {
         font-size: 16px;
         margin-top: 15px;
         margin-left: 20px;
         margin-bottom: 10px;
         width: 260px;
     }
   }

@keyframes HeroAnimation {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }