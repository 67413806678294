.desktop_image_1 { display: none !important;} 
        @media only screen and (min-width: 1199px) { .desktop_image_1 { display: block !important;} }

.desktop_image_2 { display: none !important;} 
        @media only screen and (min-width: 819px) and (max-width: 1198px) { .desktop_image_2 { display: block !important;} }

.desktop_image_3 { display: none !important;} 
        @media only screen and (min-width: 750px) and (max-width: 818px) { .desktop_image_3 { display: block !important;} }

.tablet_image_1 { display: none !important;} 
        @media only screen and (min-width: 478px) and (max-width: 749px) { .tablet_image_1  { display: block !important;} }

.nobile_image_1 { display: none !important;} 
        @media only screen and (min-width: 0px) and (max-width: 477px) { .nobile_image_1  { display: block !important;} }


.background {

  top: 0;
  margin-left: 0;
  position: absolute;
  z-index: -1;
  width: 100%;
}


/* .hero-image {
  background-image: url('../images/hero.jpg');
  top: 0;
  height: 100%;
  width: 100vw;
  background-position: top left;
  background-repeat: no-repeat;
  background-size: auto;
  position: absolute;
  background-position: top;
  z-index: -1;
 
} */

.hero-container {

  background: repeat 1;
    padding: 0 calc(50% - 700px);
    flex-direction: column;
    align-items: left;
    
}


.hero-container-h3 {
    padding-top: 350px;
    margin-bottom: 24px;
    font-weight: 300;
    color: #C8C8C8;
    letter-spacing: 4px;
    font-size: 14px;
    line-height: 16px;
    max-width: 614px;
    opacity: 100;
    text-align: left;
    animation: HeroAnimation;
    animation-duration: 3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
}

.hero-container-p {
    margin-top: 26px;
    max-width: 680px;
    color: rgba(255, 255,255, 1);
    font-size: 24px;
    line-height: 30px;
    text-align: left;
    animation: HeroAnimation 3s 0.2s forwards cubic-bezier(0.2, 0.8, 0.2, 1);
    opacity: 100;
}

.hero-container-h1 {
    margin: 0;
    color: white;
    font-size: 74px;
    line-height: 74px;
    max-width: 614px;
    opacity: 100;
    text-align: left;
    animation: HeroAnimation;
    animation-duration: 3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
}

.Linktoproject {
  text-decoration: none;
}
.Link {
    position: relative;
    height: 10px;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    font-size: 18px;
    line-height: 30px;
    font-weight: 600;
    color: white;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}


.Link:after {
    display: block;
    position: absolute;
    content: "";
    background-color: #ffffff;
    height: 3px;
    width: 0%;
    left: 50%;
    -webkit-transition: width .3s ease-in-out;
    -moz--transition: width .3s ease-in-out;
    transition: width .3s ease-in-out;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
}

.Link:hover:after{
    align-self: center;
    width: 100%;
  }
  
.Link:focus:after{
    right: 50%;
  }

.Links {
    margin-top: 23px;
    max-width: 310px;
    height: 20px;
    line-height: 30px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    align-items: left;
    justify-items: left;
    animation: HeroAnimation;
    animation-duration: 3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  }

  .HeroUIUX {
    position: relative;
    margin-left: 540px;
    margin-top: -22px;
    margin-bottom: 0;
  }

  .HeroUIUX-img {
    width: 119px;
  }
  
  .H22 {
    margin: 0;
    align-self: left;
    position: relative;
    margin-top: -5px;
  }

  .H22-img{
  width: 657px;
  }
  
  .large {
    text-decoration: none;
    font-weight: 600;
    text-align: center;
    margin-bottom: 200px;
    margin-top: 10px;
  }

  .Logos { 
    max-width: 720px;
    display: grid;
    grid-template-columns: repeat(6, 6Fr);
    grid-gap: 0px;
    margin-top: 30px;
    justify-items: left;
  }
  .Logos-img { 
  width: 50px;
  }

  .or {
    margin-top: 0px;
    height: 20px;
    margin-left: 24px;
    margin-right: 24px;
    vertical-align: middle;
    color: #AAAAAA;
    font-size: 16px;
    line-height: 30px;
    opacity: 1000;
  }

  .hero-container-h5 {
    margin: 0;
    margin-top: 20px;
    color: #ffffff;
    font-size: 70px;
    line-height: 84px;
    text-align: left;
    font-weight: 700;
    margin-bottom: 60px;
    animation: HeroAnimation;
    animation-duration: 3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  }
  
  .Cards {
    margin-top: 500px;
  }
  
  .hero-container-h6 {
    margin: 0;
    font-weight: 300;
    color: #C8C8C8;
    letter-spacing: 4px;
    font-size: 14px;
    line-height: 16px;
    max-width: 614px;
    opacity: 100;
    text-align: left;
    animation: HeroAnimation;
    animation-duration: 3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  }

.Instagram {
  margin-top: 150px;
}

  .CardGroup {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;
  }
  
  .InstaGroupCard {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 60px;
    margin-bottom: 150px;
  }
  
  
  .bobo {
    position: relative;
    margin-top: 150px;
    font-weight: 300;
    color: #C8C8C8;
    letter-spacing: 4px;
    font-size: 14px;
    line-height: 16px;
    opacity: 100;
    text-align: center;
    animation: HeroAnimation;
    animation-duration: 3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  }
  
  .Wrapper1 img {
    position: relative;
  }
  
  .internal-with-icon {
    align-content: center;
    margin-top: 0px;
    font-size: 70px;
    color: #ffffff;
    position: relative;
    height: 10px;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
  }
  
  .internal-with-icon:after {
    display: block;
      position: absolute;
      content: "";
      background-color: #ffffff;
      height: 3px;
      width: 0%;
      left: 50%;
      -webkit-transition: width .3s ease-in-out;
      -moz--transition: width .3s ease-in-out;
      transition: width .3s ease-in-out;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      transform: translateX(-50%);
     
  }
  
  .internal-with-icon:hover:after{
    align-self: center;
    width: 100%;
  }
  
  .internal-with-icon:focus:after{
    right: 50%;
  }


  .top-bot {
    width: 100px;
    z-index: 999;
    color: green;
  }

  .linktoinsta {
    text-decoration: none;
  }


  @media (max-width: 2200px) {
    .Cards {
      margin-top: 200px;
    }
  }

  @media (max-width: 1920px) {
    .Cards {
      margin-top: 220px;
    }
  }
  @media (max-width: 1800px) {
    .hero-container-h3 {
    padding-top: 300px;
    }
    .Cards {
      margin-top: 200px;
    }
  }


  @media (min-width: 1700px) and (max-width: 1750px) {
    .hero-container-h3 {
    padding-top: 300px;
    }
    .Cards {
      margin-top: 200px;
    }
  }

  @media (max-width: 1699px) {
    .hero-container {
      padding: 0 calc(50% - 530px);
    }
    .hero-container-h3 {
      padding-top: 220px;
      margin-bottom: 18px;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 14px;
      line-height: 16px;
      max-width: 614px;
      opacity: 100;
  }
  .hero-container-h1 {
    margin: 0;
    color: white;
    font-size: 54px;
    line-height: 54px;
    max-width: 614px;
}
  .hero-container-p {
      margin-top: 20px;
      max-width: 540px;
      color: rgba(255, 255,255, 1);
      font-size: 20px;
      line-height: 28px;
  }
  .Link {
      height: 10px;
  }
  .or {
    margin-top: 0px;
    height: 20px;
    margin-left: 8px;
    margin-right: 8px;
    vertical-align: middle;
    color: #AAAAAA;
    font-size: 16px;
    line-height: 30px;
    opacity: 1000;
  }
  .HeroUIUX {
    position: relative;
    margin-left: 430px;
    margin-top: -18px;
    margin-bottom: 0;
  }
  .HeroUIUX-img {
    width: 100px;
  }
    .InstaGroupCard {
      grid-gap: 45px;
    }
    .H22 {
      margin: 0;
      align-self: left;
      position: relative;
      margin-top: -5px;
    }
    .H22-img{
    width: 530px;
    }
    .Logos { 
      max-width: 574px;
      grid-template-columns: repeat(6, 6Fr);
      grid-gap: 0px;
      margin-top: 20px;
      justify-items: left;
    }
    .Logos-img { 
    width: 48px;
    }
    .hero-container-h5 {
      margin: 0;
      margin-top: 0px;
      color: #ffffff;
      font-size: 54px;
      line-height: 84px;
      text-align: left;
      font-weight: 700;
      margin-bottom: 30px;
    }
    
    .Cards {
      margin-top: 250px;
    }
    
    .hero-container-h6 {
      margin: 0;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 14px;
      line-height: 16px;
      max-width: 614px;
    }
    .Instagram {
      margin-top: 100px;
    }
    .large {
      text-decoration: none;
      font-weight: 600;
      text-align: center;
      margin-bottom: 150px;
      margin-top: 0px;
    }
    .internal-with-icon {
      align-content: center;
      margin-top: 0px;
      font-size: 54px;
      color: #ffffff;
      position: relative;
      height: 10px;
    }
    .bobo {
      position: relative;
      margin-top: 0px;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 14px;
    }
    .InstaGroupCard {
      margin-bottom: 100px;
    }
    
  }

  @media (max-width: 1400px) {
    .hero-container {
      padding: 0 calc(50% - 450px);
    }
    .hero-container-h3 {
      padding-top: 190px;
      margin-bottom: 10px;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 14px;
      line-height: 16px;
      max-width: 614px;
      opacity: 100;
  }
  .hero-container-h1 {
    margin: 0;
    color: white;
    font-size: 44px;
    line-height: 54px;
    max-width: 400px;
}
  .hero-container-p {
      margin-top: 14px;
      max-width: 500px;
      color: rgba(255, 255,255, 1);
      font-size: 18px;
      line-height: 24px;
  }
  .Link {
      height: 10px;
      font-size: 16px;
  }
  .Links {
    margin-top: 0px;
}
.or {
  margin-top: 0px;
  height: 20px;
  margin-left: 6px;
  margin-right: 6px;
  vertical-align: middle;
  color: #AAAAAA;
  font-size: 16px;
  line-height: 30px;
  opacity: 1000;
}
  .HeroUIUX {
    position: relative;
    margin-left: 405px;
    margin-top: -18px;
    margin-bottom: 0;
  }
  .HeroUIUX-img {
    width: 80px;
  }
    .InstaGroupCard {
      grid-gap: 40px;
    }
    .H22 {
      margin: 0;
      align-self: left;
      position: relative;
      margin-top: -5px;
    }
    .H22-img{
    width: 490px;
    }
    .Logos { 
      max-width: 530px;
      grid-template-columns: repeat(6, 6Fr);
      grid-gap: 0px;
      margin-top: 16px;
      justify-items: left;
    }
    .Logos-img { 
    width: 44px;
    }
    .hero-container-h5 {
      margin: 0;
      margin-top: 0px;
      color: #ffffff;
      font-size: 44px;
      line-height: 64px;
      text-align: left;
      font-weight: 700;
      margin-bottom: 20px;
    }
    
    .Cards {
      margin-top: 200px;
    }
    
    .hero-container-h6 {
      margin: 0;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 14px;
      line-height: 16px;
      max-width: 614px;
    }
    .Instagram {
      margin-top: 100px;
    }
    .large {
      text-decoration: none;
      font-weight: 600;
      text-align: center;
      margin-bottom: 150px;
      margin-top: 0px;
    }
    .internal-with-icon {
      align-content: center;
      margin-top: 0px;
      font-size: 44px;
      color: #ffffff;
      position: relative;
      height: 10px;
    }

    .arrowlink {
      width: 40px;
    }

    .bobo {
      position: relative;
      margin-top: 0px;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 14px;
    }
    .InstaGroupCard {
      margin-bottom: 100px;
    }
    .CardGroup {
      position: relative;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 50px;
    }
  }
  
  @media (max-width: 1199px) {
    .hero-container {
      padding: 0 calc(50% - 400px);
    }
    .hero-container-h3 {
      padding-top: 150px;
      margin-bottom: 5px;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
      line-height: 16px;
      max-width: 614px;
      opacity: 100;
  }
  .hero-container-h1 {
    margin: 0;
    color: white;
    font-size: 34px;
    line-height: 40px;
    max-width: 400px;
}
  .hero-container-p {
      margin-top: 8px;
      margin-bottom: 8px;
      max-width: 450px;
      color: rgba(255, 255,255, 1);
      font-size: 16px;
      line-height: 24px;
  }
  .Link {
      height: 10px;
      font-size: 16px;
  }
  .Links {
    margin-top: 0px;
}
.or {
  margin-top: 0px;
  height: 20px;
  margin-left: 0px;
  margin-right: 0px;
  vertical-align: middle;
  color: #AAAAAA;
  font-size: 16px;
  line-height: 30px;
  opacity: 1000;
}
  .HeroUIUX {
    position: relative;
    margin-left: 360px;
    margin-top: -14px;
    margin-bottom: 0;
  }
  .HeroUIUX-img {
    width: 80px;
  }
    .InstaGroupCard {
      grid-gap: 34px;
    }
    .H22 {
      margin: 0;
      align-self: left;
      position: relative;
      margin-top: -5px;
    }
    .H22-img{
    width: 440px;
    }
    .Logos { 
      max-width: 470px;
      grid-template-columns: repeat(6, 6Fr);
      grid-gap: 0px;
      margin-top: 16px;
      justify-items: left;
    }
    .Logos-img { 
    width: 44px;
    }
    .hero-container-h5 {
      margin: 0;
      margin-top: 0px;
      color: #ffffff;
      font-size: 34px;
      line-height: 40px;
      text-align: left;
      font-weight: 700;
      margin-bottom: 20px;
    }
    
    .Cards {
      margin-top: 150px;
    }
    
    .hero-container-h6 {
      margin: 0;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
      line-height: 16px;
      max-width: 614px;
    }
    .Instagram {
      margin-top: 50px;
    }
    .large {
      text-decoration: none;
      font-weight: 600;
      text-align: center;
      margin-bottom: 60px;
      margin-top: 0px;
    }
    .internal-with-icon {
      align-content: center;
      margin-top: 0px;
      font-size: 34px;
      line-height: 40px;
      color: #ffffff;
      position: relative;
      height: 10px;
    }

    .arrowlink {
      width: 30px;
    }

    .bobo {
      position: relative;
      margin-top: 0px;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
    }
    .InstaGroupCard {
      margin-bottom: 60px;
    }
    .CardGroup {
      position: relative;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 45px;
    }
  }
  

  @media (max-width: 969px) {
    .hero-container {
      padding: 0 calc(50% - 380px);
    }
    .hero-container-h3 {
      padding-top: 120px;
      margin-bottom: 5px;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
      line-height: 16px;
      max-width: 614px;
      opacity: 100;
  }
  .hero-container-h1 {
    margin: 0;
    color: white;
    font-size: 34px;
    line-height: 40px;
    max-width: 400px;
}
  .hero-container-p {
      margin-top: 8px;
      margin-bottom: 8px;
      max-width: 450px;
      color: rgba(255, 255,255, 1);
      font-size: 16px;
      line-height: 24px;
  }
  .Link {
      height: 10px;
      font-size: 16px;
  }
  .Links {
    margin-top: 0px;
}
.or {
  margin-top: 0px;
  height: 20px;
  margin-left: 0px;
  margin-right: 0px;
  vertical-align: middle;
  color: #AAAAAA;
  font-size: 16px;
  line-height: 30px;
  opacity: 1000;
}
  .HeroUIUX {
    position: relative;
    margin-left: 360px;
    margin-top: -14px;
    margin-bottom: 0;
  }
  .HeroUIUX-img {
    width: 80px;
  }
    .InstaGroupCard {
      grid-gap: 26px;
    }
    .H22 {
      margin: 0;
      align-self: left;
      position: relative;
      margin-top: -5px;
    }
    .H22-img{
    width: 440px;
    }
    .Logos { 
      max-width: 470px;
      grid-template-columns: repeat(6, 6Fr);
      grid-gap: 0px;
      margin-top: 16px;
      justify-items: left;
    }
    .Logos-img { 
    width: 44px;
    }
    .hero-container-h5 {
      margin: 0;
      margin-top: 0px;
      color: #ffffff;
      font-size: 34px;
      line-height: 40px;
      text-align: left;
      font-weight: 700;
      margin-bottom: 20px;
    }
    
    .Cards {
      margin-top: 100px;
    }
    
    .hero-container-h6 {
      margin: 0;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
      line-height: 16px;
      max-width: 614px;
    }
    .Instagram {
      margin-top: 50px;
    }
    .large {
      text-decoration: none;
      font-weight: 600;
      text-align: center;
      margin-bottom: 60px;
      margin-top: 0px;
    }
    .internal-with-icon {
      align-content: center;
      margin-top: 0px;
      font-size: 34px;
      line-height: 40px;
      color: #ffffff;
      position: relative;
      height: 10px;
    }

    .arrowlink {
      width: 30px;
    }

    .bobo {
      position: relative;
      margin-top: 0px;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
    }
    .InstaGroupCard {
      margin-bottom: 60px;
    }
    .CardGroup {
      position: relative;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 40px;
    }
    .linktoinsta {
      width: 236px;
    }
  }
  
  @media (max-width: 819px) {
    .hero-container {
      padding: 0 calc(50% - 360px);
    }
    .hero-container-h3 {
      padding-top: 120px;
      margin-bottom: 5px;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
      line-height: 16px;
      max-width: 614px;
      opacity: 100;
  }
  .hero-container-h1 {
    margin: 0;
    color: white;
    font-size: 34px;
    line-height: 40px;
    max-width: 400px;
}
  .hero-container-p {
      margin-top: 8px;
      margin-bottom: 8px;
      max-width: 390px;
      color: rgba(255, 255,255, 1);
      font-size: 16px;
      line-height: 24px;
  }
  .Link {
      height: 10px;
      font-size: 16px;
  }
  .Links {
    margin-top: 0px;
}
.or {
  margin-top: 0px;
  height: 20px;
  margin-left: 0px;
  margin-right: 0px;
  vertical-align: middle;
  color: #AAAAAA;
  font-size: 16px;
  line-height: 30px;
  opacity: 100;
}
  .HeroUIUX {
    position: relative;
    margin-left: 360px;
    margin-top: -14px;
    margin-bottom: 0;
  }
  .HeroUIUX-img {
    width: 80px;
  }
    .InstaGroupCard {
      grid-gap: 14px;
    }
    .H22 {
      margin: 0;
      align-self: left;
      position: relative;
      margin-top: -5px;
    }
    .H22-img{
    width: 440px;
    }
    .Logos { 
      max-width: 470px;
      grid-template-columns: repeat(6, 6Fr);
      grid-gap: 0px;
      margin-top: 16px;
      justify-items: left;
    }
    .Logos-img { 
    width: 44px;
    }
    .hero-container-h5 {
      margin: 0;
      margin-top: 0px;
      color: #ffffff;
      font-size: 34px;
      line-height: 40px;
      text-align: left;
      font-weight: 700;
      margin-bottom: 20px;
    }
    
    .Cards {
      margin-top: 100px;
    }
    
    .hero-container-h6 {
      margin: 0;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
      line-height: 16px;
      max-width: 614px;
    }
    .Instagram {
      margin-top: 50px;
    }
    .large {
      text-decoration: none;
      font-weight: 600;
      text-align: center;
      margin-bottom: 60px;
      margin-top: 0px;
    }
    .internal-with-icon {
      align-content: center;
      margin-top: 0px;
      font-size: 34px;
      line-height: 40px;
      color: #ffffff;
      position: relative;
      height: 10px;
    }

    .arrowlink {
      width: 30px;
    }

    .bobo {
      position: relative;
      margin-top: 0px;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
    }
    .InstaGroupCard {
      margin-bottom: 60px;
    }
    .CardGroup {
      position: relative;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
    }
    .linktoinsta {
      width: 230px;
    }
  }

  @media (max-width: 749px) {
    .hero-container {
      /* padding: 0 calc(50% - 280px); */
      max-width: 100%;
      margin: auto;
      padding: 0 60px 0 60px;
    }
    .hero-container-h3 {
      padding-top: 520px;
      margin-bottom: 5px;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
      line-height: 16px;
      max-width: 614px;
      opacity: 100;
  }
  .hero-container-h1 {
    margin: 0;
    color: white;
    font-size: 34px;
    line-height: 40px;
    max-width: 100%;
}
  .hero-container-p {
      margin-top: 8px;
      margin-bottom: 8px;
      max-width: 100%;
      color: rgba(255, 255,255, 1);
      font-size: 16px;
      line-height: 24px;
  }
  .Link {
      height: 10px;
      font-size: 16px;
  }
  .Links {
    margin-top: 0px;
}
.or {
  margin-top: 0px;
  height: 20px;
  margin-left: 0px;
  margin-right: 0px;
  vertical-align: middle;
  color: #AAAAAA;
  font-size: 16px;
  line-height: 30px;
  opacity: 100;
}
  .HeroUIUX {
    position: relative;
    margin-left: 85%;
    margin-top: -14px;
    margin-bottom: 0;
  }
  .HeroUIUX-img {
    width: 80px;
  }
    .InstaGroupCard {
      grid-gap: 20px;
      grid-template-columns: repeat(1, 1fr);
    }
    .H22 {
      margin: 0;
      align-self: left;
      position: relative;
      margin-top: -5px;
    }
    .H22-img{
    width: 100%;
    }
    .Logos { 
      max-width: 100%;
      grid-template-columns: repeat(6, 6Fr);
      grid-gap: 0px;
      margin-top: 16px;
      justify-items: left;
    }
    .Logos-img { 
    width: 44px;
    }
    .hero-container-h5 {
      margin: 0;
      margin-top: 0px;
      color: #ffffff;
      font-size: 34px;
      line-height: 40px;
      text-align: left;
      font-weight: 700;
      margin-bottom: 20px;
    }
    
    .Cards {
      margin-top: 100px;
    }
    
    .hero-container-h6 {
      margin: 0;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
      line-height: 16px;
      max-width: 614px;
    }
    .Instagram {
      margin-top: 50px;
    }
    .large {
      text-decoration: none;
      font-weight: 600;
      text-align: center;
      margin-bottom: 60px;
      margin-top: 0px;
    }
    .internal-with-icon {
      align-content: center;
      margin-top: 0px;
      font-size: 34px;
      line-height: 40px;
      color: #ffffff;
      position: relative;
      height: 10px;
    }

    .arrowlink {
      width: 30px;
    }

    .bobo {
      position: relative;
      margin-top: 0px;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
    }
    .InstaGroupCard {
      margin-bottom: 60px;
    }
    .CardGroup {
      padding-right: 0px;
      position: relative;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 20px;
    }
    .linktoinsta {
      width: 100%;
    }
  }


  @media (max-width: 599px) {
    .hero-container {
      /* padding: 0 calc(50% - 280px); */
      max-width: 100%;
      margin: auto;
      padding: 0 20px 0 20px;
    }
    .hero-container-h3 {
      padding-top: 450px;
      margin-bottom: 5px;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
      line-height: 16px;
      max-width: 614px;
      opacity: 100;
  }
  .hero-container-h1 {
    margin: 0;
    color: white;
    font-size: 34px;
    line-height: 40px;
    max-width: 400px;
}
  .hero-container-p {
      margin-top: 8px;
      margin-bottom: 8px;
      max-width: 100%;
      color: rgba(255, 255,255, 1);
      font-size: 16px;
      line-height: 24px;
  }
  .Link {
      height: 10px;
      font-size: 16px;
  }
  .Links {
    margin-top: 0px;
}
.or {
  margin-top: 0px;
  height: 20px;
  margin-left: 0px;
  margin-right: 0px;
  vertical-align: middle;
  color: #AAAAAA;
  font-size: 16px;
  line-height: 30px;
  opacity: 100;
}
  .HeroUIUX {
    position: relative;
    margin-left: 82%;
    margin-top: -14px;
    margin-bottom: 0;
  }
  .HeroUIUX-img {
    width: 60px;
  }
    .InstaGroupCard {
      grid-gap: 20px;
      grid-template-columns: repeat(1, 1fr);
    }
    .H22 {
      margin: 0;
      align-self: left;
      position: relative;
      margin-top: -5px;
    }
    .H22-img{
    width: 95%;
    }
    .Logos { 
      max-width: 100%;
      grid-template-columns: repeat(6, 6Fr);
      grid-gap: 0px;
      margin-top: 16px;
      justify-items: left;
    }
    .Logos-img { 
    width: 44px;
    }
    .hero-container-h5 {
      margin: 0;
      margin-top: 0px;
      color: #ffffff;
      font-size: 34px;
      line-height: 40px;
      text-align: left;
      font-weight: 700;
      margin-bottom: 20px;
    }
    
    .Cards {
      margin-top: 60px;
    }
    
    .hero-container-h6 {
      margin: 0;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
      line-height: 16px;
      max-width: 614px;
    }
    .Instagram {
      margin-top: 50px;
    }
    .large {
      text-decoration: none;
      font-weight: 600;
      text-align: center;
      margin-bottom: 60px;
      margin-top: 0px;
    }
    .internal-with-icon {
      align-content: center;
      margin-top: 0px;
      font-size: 34px;
      line-height: 40px;
      color: #ffffff;
      position: relative;
      height: 10px;
    }

    .arrowlink {
      width: 30px;
    }

    .bobo {
      position: relative;
      margin-top: 0px;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
    }
    .InstaGroupCard {
      margin-bottom: 60px;
    }
    .CardGroup {
      padding-right: 0px;
      position: relative;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 20px;
    }
    .linktoinsta {
      width: 100%;
    }
  }


  @media (max-width: 580px) {
    .hero-container-h3 {
      padding-top: 480px;
      margin-bottom: 5px;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
      line-height: 16px;
      max-width: 614px;
      opacity: 100;
  }
}
  @media (max-width: 550px) {
    .hero-container-h3 {
      padding-top: 440px;
      margin-bottom: 5px;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
      line-height: 16px;
      max-width: 614px;
      opacity: 100;
  }
}
  @media (max-width: 530px) {
    .hero-container-h3 {
      padding-top: 400px;
      margin-bottom: 5px;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
      line-height: 16px;
      max-width: 614px;
      opacity: 100;
  }
}
  @media (max-width: 500px) {
    .hero-container-h3 {
      padding-top: 380px;
      margin-bottom: 5px;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
      line-height: 16px;
      max-width: 614px;
      opacity: 100;
  }
}
  @media (max-width: 480px) {
    .hero-container-h3 {
      padding-top: 420px;
      margin-bottom: 5px;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
      line-height: 16px;
      max-width: 614px;
      opacity: 100;
  }
  }
  @media (max-width: 413px) {
    .hero-container {
      /* padding: 0 calc(50% - 280px); */
      max-width: 100%;
      margin: auto;
      padding: 0 20px 0 20px;
    }
    .hero-container-h3 {
      padding-top: 350px;
      margin-bottom: 5px;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
      line-height: 16px;
      max-width: 614px;
      opacity: 100;
  }
  .hero-container-h1 {
    margin: 0;
    color: white;
    font-size: 34px;
    line-height: 40px;
    max-width: 400px;
}
  .hero-container-p {
      margin-top: 8px;
      margin-bottom: 8px;
      max-width: 450px;
      color: rgba(255, 255,255, 1);
      font-size: 16px;
      line-height: 24px;
  }
  .Link {
      height: 10px;
      font-size: 16px;
  }
  .Links {
    margin-top: 0px;
}
.or {
  margin-top: 0px;
  height: 20px;
  margin-left: 0px;
  margin-right: 0px;
  vertical-align: middle;
  color: #AAAAAA;
  font-size: 16px;
  line-height: 30px;
  opacity: 100;
}
  .HeroUIUX {
    display: grid;
    justify-items: right;
    position: relative;
    right: 0;
    margin-left: 0px;
    margin-top: 20px;
    margin-bottom: 0;
  }
  .HeroUIUX-img {
    justify-content: right;
    right: 0;
    width: 60px;
  }
    .InstaGroupCard {
      grid-gap: 20px;
      grid-template-columns: repeat(1, 1fr);
    }
    .H22 {
      margin: 0;
      align-self: left;
      position: relative;
      margin-top: -5px;
    }
    .H22-img{
    width: 100%;
    }
    .Logos { 
      max-width: 470px;
      grid-template-columns: repeat(6, 6Fr);
      grid-gap: 0px;
      margin-top: 16px;
      justify-items: left;
    }
    .Logos-img { 
    width: 44px;
    }
    .hero-container-h5 {
      margin: 0;
      margin-top: 0px;
      color: #ffffff;
      font-size: 34px;
      line-height: 40px;
      text-align: left;
      font-weight: 700;
      margin-bottom: 20px;
    }
    
    .Cards {
      margin-top: 60px;
    }
    
    .hero-container-h6 {
      margin: 0;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
      line-height: 16px;
      max-width: 614px;
    }
    .Instagram {
      margin-top: 50px;
    }
    .large {
      text-decoration: none;
      font-weight: 600;
      text-align: center;
      margin-bottom: 60px;
      margin-top: 0px;
    }
    .internal-with-icon {
      align-content: center;
      margin-top: 0px;
      font-size: 34px;
      line-height: 40px;
      color: #ffffff;
      position: relative;
      height: 10px;
    }

    .arrowlink {
      width: 30px;
    }

    .bobo {
      position: relative;
      margin-top: 0px;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
    }
    .InstaGroupCard {
      margin-bottom: 60px;
    }
    .CardGroup {
      padding-right: 0px;
      position: relative;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 20px;
    }
    .linktoinsta {
      width: 100%;
    }
  }


  @media (max-width: 359px) {
    .hero-container {
      /* padding: 0 calc(50% - 280px); */
      max-width: 100%;
      margin: auto;
      padding: 0 20px 0 20px;
    }
    .hero-container-h3 {
      padding-top: 300px;
      margin-bottom: 5px;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
      line-height: 16px;
      max-width: 614px;
      opacity: 100;
  }
  .hero-container-h1 {
    margin: 0;
    color: white;
    font-size: 32px;
    line-height: 40px;
    max-width: 400px;
}
  .hero-container-p {
      margin-top: 8px;
      margin-bottom: 8px;
      max-width: 450px;
      color: rgba(255, 255,255, 1);
      font-size: 16px;
      line-height: 24px;
  }
  .Link {
      height: 10px;
      font-size: 16px;
  }
  .Links {
    margin-top: 0px;
}
.or {
  margin-top: 0px;
  height: 20px;
  margin-left: 0px;
  margin-right: 0px;
  vertical-align: middle;
  color: #AAAAAA;
  font-size: 16px;
  line-height: 30px;
  opacity: 100;
}
  .HeroUIUX {
    display: grid;
    justify-items: right;
    position: relative;
    right: 0;
    margin-left: 0px;
    margin-top: 20px;
    margin-bottom: 0;
  }
  .HeroUIUX-img {
    justify-content: right;
    right: 0;
    width: 60px;
  }
    .InstaGroupCard {
      grid-gap: 20px;
      grid-template-columns: repeat(1, 1fr);
    }
    .H22 {
      margin: 0;
      align-self: left;
      position: relative;
      margin-top: -5px;
    }
    .H22-img{
    width: 100%;
    }
    .Logos { 
      max-width: 470px;
      grid-template-columns: repeat(6, 6Fr);
      grid-gap: 8px;
      margin-top: 16px;
      justify-items: left;
    }
    .Logos-img { 
    width: 36px;
    }
    .hero-container-h5 {
      margin: 0;
      margin-top: 0px;
      color: #ffffff;
      font-size: 34px;
      line-height: 40px;
      text-align: left;
      font-weight: 700;
      margin-bottom: 20px;
    }
    
    .Cards {
      margin-top: 60px;
    }
    
    .hero-container-h6 {
      margin: 0;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
      line-height: 16px;
      max-width: 614px;
    }
    .Instagram {
      margin-top: 50px;
    }
    .large {
      text-decoration: none;
      font-weight: 600;
      text-align: center;
      margin-bottom: 60px;
      margin-top: 0px;
    }
    .internal-with-icon {
      align-content: center;
      margin-top: 0px;
      font-size: 34px;
      line-height: 40px;
      color: #ffffff;
      position: relative;
      height: 10px;
    }

    .arrowlink {
      width: 30px;
    }

    .bobo {
      position: relative;
      margin-top: 0px;
      font-weight: 300;
      color: #C8C8C8;
      letter-spacing: 4px;
      font-size: 12px;
    }
    .InstaGroupCard {
      margin-bottom: 60px;
    }
    .CardGroup {
      padding-right: 0px;
      position: relative;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 20px;
    }
    .linktoinsta {
      width: 100%;
    }
  }


 