.main-liveshop {
    color: white;
    padding-top: 3em;
    margin-bottom: 100px;
    bottom: 0;
    width: 100%;
    z-index: 1;
    padding: 0 calc(50% - 700px);
}

.row-gest-one {
    display: grid;
    grid-template-columns: 500px 900px;
    /* grid-template-columns: repeat(4, 1fr); */
    /* grid-gap: 318px; */
    margin-top: 200px;
    justify-content: space-between;
}

.row-gest-h1 {
    margin-top: 5px;
    margin-bottom: 0px;
    font-size: 46px;
    line-height: 50px;
}

.gest-col-1 {
    padding: 0;
    width: 380px;
    animation: HeroAnimation;
    animation-duration: 3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
}


.gest-col-2 {
    justify-self: end;
    color: #A8A9AD;
    width: 720px;
    animation: HeroAnimation;
    animation-duration: 3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
}

.row-h4 {
    letter-spacing: 4px;
    margin-top: 0px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    margin-bottom: 9px;
}

.list-unstyled-1 {
    font-size: 17px;
    color: #888A8E;
    list-style: none;
    padding: 0;
    width: 405px;
    font-weight: 300;
    line-height: 24px;
}

.span1 {
    color: #888A8E;
}

.contact-1 {
    color: #ffffff;
}


.list-unstyled-2 {
    font-size: 17px;
    color: #ffffff;
    list-style: none;
    padding: 0;
    font-weight: 300;
    line-height: 24px;
}

.list-unstyled-3 {
    font-size: 17px;
    color: #888A8E;
    list-style: none;
    padding: 0;
    font-weight: 300;
    line-height: 24px;
}

.list-project-info {
    right: 0;
    list-style: none;
    padding: 0;
    margin: 0;
}

.list-project-info-text {
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    font-weight: 300;
    margin-bottom: 30px;

}


.footer-link {
    align-content: center;
    color: #ffffff;
    position: relative;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
  }
  
  .footer-link:after {
    display: block;
      position: absolute;
      content: "";
      background-color: #ffffff;
      height: 3px;
      width: 0%;
      left: 50%;
      -webkit-transition: width .3s ease-in-out;
      -moz--transition: width .3s ease-in-out;
      transition: width .3s ease-in-out;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      transform: translateX(-50%);
     
  }
  
  .footer-link:hover:after{
    align-self: center;
    width: 100%;
  }
  
  .footer-link:focus:after{
    right: 50%;
  }

.client {
    margin-top: 38px;
    padding: 0px;
    margin-bottom: 0;
}
.my-role {
    margin-top: 38px;
    padding: 0px;
    margin-bottom: 0;
}
.year {
    margin-top: 38px;
    padding: 0px;
    margin-bottom: 0;
}

li {
    list-style: none;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
}

.liveshop-images {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 0;
}

.project-in-video {
    margin: 0;
    padding: 0;
    vertical-align: top;
    width: 1400px;
    height: 788px;
}

.project-in-img {
    margin: 0;
    padding: 0;
    vertical-align: top;
    width: 1400px;
}

@media (max-width: 1699px) {
    .projects-container {
        padding: 0 calc(50% - 530px);
    }
    .project-in-img {
        margin: 0;
        padding: 0;
        vertical-align: top;
        width: 1060px;
    }
   .row-gest-one {
    display: grid;
    grid-template-columns: 300px 760px;
    /* grid-template-columns: repeat(4, 1fr); */
    /* grid-gap: 318px; */
    margin-top: 200px;
    justify-content: space-between;
    }
    .gest-col-2 {
        justify-self: end;
        color: #A8A9AD;
        width: 620px;
    }
    .row-gest-h1 {
        margin-top: 5px;
        margin-bottom: 0px;
        font-size: 36px;
        line-height: 48px;
    }
    .my-role {
        margin-top: 24px;
        padding: 0px;
        margin-bottom: 0;
    }
    .client {
        margin-top: 24px;
        padding: 0px;
        margin-bottom: 0;
    }
    .year {
        margin-top: 24px;
        padding: 0px;
        margin-bottom: 0;
    }
    .project-in-video {
        margin: 0;
        padding: 0;
        vertical-align: top;
        width: 1060px;
        height: 606px;
    }
    .list-project-info-text {
        margin-bottom: 20px;
    }
}



@media (max-width: 1400px) {
    .projects-container {
        padding: 0 calc(50% - 450px);
    }
    .project-in-img {
        margin: 0;
        padding: 0;
        vertical-align: top;
        width: 900px;
    }
   .row-gest-one {
    display: grid;
    grid-template-columns: 240px 660px;
    /* grid-template-columns: repeat(4, 1fr); */
    /* grid-gap: 318px; */
    margin-top: 150px;
    justify-content: space-between;
    }
    .gest-col-1 {
        padding: 0;
        width: 280px;
        animation: HeroAnimation;
        animation-duration: 3s;
        animation-delay: 0.1s;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
    }
    .gest-col-2 {
        justify-self: end;
        color: #A8A9AD;
        width: 580px;
    }
    .row-gest-h1 {
        margin-top: 5px;
        margin-bottom: 0px;
        font-size: 32px;
        line-height: 42px;
    }
    .my-role {
        margin-top: 20px;
        padding: 0px;
        margin-bottom: 0;
    }
    .client {
        margin-top: 20px;
        padding: 0px;
        margin-bottom: 0;
    }
    .year {
        margin-top: 20px;
        padding: 0px;
        margin-bottom: 0;
    }
    .project-in-video {
        margin: 0;
        padding: 0;
        vertical-align: top;
        width: 900px;
        height: 516px;
    }
    .list-project-info-text {
        font-size: 18px;
        line-height: 26px;
        color: #ffffff;
        font-weight: 300;
        margin-bottom: 16px;
    }
}

@media (max-width: 1199px) {
    .projects-container {
        padding: 0 calc(50% - 400px);
    }
    .project-in-img {
        margin: 0;
        padding: 0;
        vertical-align: top;
        width: 800px;
    }
   .row-gest-one {
    display: grid;
    grid-template-columns: 200px 600px;
    /* grid-template-columns: repeat(4, 1fr); */
    /* grid-gap: 318px; */
    margin-top: 150px;
    justify-content: space-between;
    }
    .gest-col-1 {
       width: 250px;
    }
    .gest-col-2 {
        justify-self: end;
        color: #A8A9AD;
        width: 550px;
    }
    .row-gest-h1 {
        margin-top: 5px;
        margin-bottom: 0px;
        font-size: 24px;
        line-height: 32px;
    }
    .my-role {
        margin-top: 15px;
        padding: 0px;
        margin-bottom: 0;
    }
    .client {
        margin-top: 15px;
        padding: 0px;
        margin-bottom: 0;
    }
    .year {
        margin-top: 15px;
        padding: 0px;
        margin-bottom: 0;
    }
    .project-in-video {
        margin: 0;
        padding: 0;
        vertical-align: top;
        width: 800px;
        height: 458px;
    }
    .list-project-info-text {
        font-size: 16px;
        line-height: 26px;
        color: #ffffff;
        font-weight: 300;
        margin-bottom: 12px;
    }
    li {
        list-style: none;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
    }
    .row-h4 {
        letter-spacing: 3px;
        margin-top: 0px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 300;
        margin-bottom: 9px;
    }
}

@media (max-width: 969px) {
    .projects-container {
        padding: 0 calc(50% - 380px);
    }
    .project-in-img {
        margin: 0;
        padding: 0;
        vertical-align: top;
        width: 760px;
    }
   .row-gest-one {
    display: grid;
    grid-template-columns: 200px 560px;
    /* grid-template-columns: repeat(4, 1fr); */
    /* grid-gap: 318px; */
    margin-top: 150px;
    justify-content: space-between;
    }
    .gest-col-1 {
       width: 240px;
    }
    .gest-col-2 {
        justify-self: end;
        color: #A8A9AD;
        width: 520px;
    }
    .row-gest-h1 {
        margin-top: 5px;
        margin-bottom: 0px;
        font-size: 24px;
        line-height: 32px;
    }
    .my-role {
        margin-top: 15px;
        padding: 0px;
        margin-bottom: 0;
    }
    .client {
        margin-top: 15px;
        padding: 0px;
        margin-bottom: 0;
    }
    .year {
        margin-top: 15px;
        padding: 0px;
        margin-bottom: 0;
    }
    .project-in-video {
        margin: 0;
        padding: 0;
        vertical-align: top;
        width: 760px;
        height: 432px;
    }
    .list-project-info-text {
        font-size: 16px;
        line-height: 26px;
        color: #ffffff;
        font-weight: 300;
        margin-bottom: 12px;
    }
    li {
        list-style: none;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
    }
    .row-h4 {
        letter-spacing: 3px;
        margin-top: 0px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 300;
        margin-bottom: 9px;
    }
}


@media (max-width: 819px) {
    .projects-container {
        padding: 0 calc(50% - 360px);
    }
    .project-in-img {
        margin: 0;
        padding: 0;
        vertical-align: top;
        width: 720px;
    }
   .row-gest-one {
    display: grid;
    grid-template-columns: 200px 520px;
    /* grid-template-columns: repeat(4, 1fr); */
    /* grid-gap: 318px; */
    margin-top: 150px;
    justify-content: space-between;
    }
    .gest-col-1 {
       width: 240px;
    }
    .gest-col-2 {
        justify-self: end;
        color: #A8A9AD;
        width: 480px;
    }
    .row-gest-h1 {
        margin-top: 5px;
        margin-bottom: 0px;
        font-size: 24px;
        line-height: 32px;
    }
    .my-role {
        margin-top: 15px;
        padding: 0px;
        margin-bottom: 0;
    }
    .client {
        margin-top: 15px;
        padding: 0px;
        margin-bottom: 0;
    }
    .year {
        margin-top: 15px;
        padding: 0px;
        margin-bottom: 0;
    }
    .project-in-video {
        margin: 0;
        padding: 0;
        vertical-align: top;
        width: 720px;
        height: 414px;
    }
    .list-project-info-text {
        font-size: 16px;
        line-height: 26px;
        color: #ffffff;
        font-weight: 300;
        margin-bottom: 12px;
    }
    li {
        list-style: none;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
    }
    .row-h4 {
        letter-spacing: 3px;
        margin-top: 0px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 300;
        margin-bottom: 9px;
    }
}

@media (max-width: 749px) {
    .projects-container {
        max-width: 100%;
        margin: auto;
        padding: 0 20px 0 20px;
    }
    .project-in-img {
        margin: 0;
        padding: 0;
        vertical-align: top;
        width: 100%;
    }
   .row-gest-one {
    display: grid;
    grid-template-columns: 200px 360px;
    /* grid-template-columns: repeat(4, 1fr); */
    /* grid-gap: 318px; */
    margin-top: 100px;
    justify-content: space-between;
    }
    .gest-col-1 {
       width: 180px;
    }
    .gest-col-2 {
        justify-self: end;
        color: #A8A9AD;
        width: 360px;
    }
    .row-gest-h1 {
        margin-top: 5px;
        margin-bottom: 0px;
        font-size: 24px;
        line-height: 32px;
    }
    .my-role {
        margin-top: 15px;
        padding: 0px;
        margin-bottom: 0;
    }
    .client {
        margin-top: 15px;
        padding: 0px;
        margin-bottom: 0;
    }
    .year {
        margin-top: 15px;
        padding: 0px;
        margin-bottom: 0;
    }
    .project-in-video {
        margin: 0;
        padding: 0;
        vertical-align: top;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .videoWrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
    }
    .list-project-info-text {
        font-size: 16px;
        line-height: 26px;
        color: #ffffff;
        font-weight: 300;
        margin-bottom: 12px;
    }
    li {
        list-style: none;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
    }
    .row-h4 {
        letter-spacing: 3px;
        margin-top: 0px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 300;
        margin-bottom: 9px;
    }
}

@media (max-width: 599px) {
    .projects-container {
        max-width: 100%;
        margin: auto;
        padding: 0 20px 0 20px;
    }
    .project-in-img {
        margin: 0;
        padding: 0;
        vertical-align: top;
        width: 100%;
    }
   .row-gest-one {
    display: grid;
    /* grid-template-columns: 200px 360px; */
    grid-template-columns: repeat(1, 1fr);
    /* grid-gap: 318px; */
    margin-top: 100px;
    justify-content: space-between;
    }
    .gest-col-1 {
       display: grid;
       grid-template-columns: repeat(1, 2fr);
       width: 100%;
    }
    .gest-col-2 {
        justify-self: start;
        color: #A8A9AD;
        width: 100%;
        margin-top: 20px;
    }
    .row-gest-h1 {
        margin-top: 5px;
        margin-bottom: 0px;
        font-size: 24px;
        line-height: 32px;
    }
    .my-role {
        margin-top: 15px;
        padding: 0px;
        margin-bottom: 0;
    }
    .my-role-list {
        display: grid;
        width: 300px;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 0px;
    }
    .client {
        margin-top: 15px;
        padding: 0px;
        margin-bottom: 0;
    }
    .year {
        margin-top: 15px;
        padding: 0px;
        margin-bottom: 0;
    }
    .project-in-video {
        margin: 0;
        padding: 0;
        vertical-align: top;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .videoWrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
    }
    .list-project-info-text {
        font-size: 16px;
        line-height: 26px;
        color: #ffffff;
        font-weight: 300;
        margin-bottom: 12px;
    }
    li {
        list-style: none;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
    }
    .row-h4 {
        letter-spacing: 3px;
        margin-top: 0px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 300;
        margin-bottom: 9px;
    }
}

@media (max-width: 359px) {
    .projects-container {
        max-width: 100%;
        margin: auto;
        padding: 0 20px 0 20px;
    }
    .project-in-img {
        margin: 0;
        padding: 0;
        vertical-align: top;
        width: 100%;
    }
   .row-gest-one {
    display: grid;
    /* grid-template-columns: 200px 360px; */
    grid-template-columns: repeat(1, 1fr);
    /* grid-gap: 318px; */
    margin-top: 100px;
    justify-content: space-between;
    }
    .gest-col-1 {
       display: grid;
       grid-template-columns: repeat(1, 2fr);
       width: 100%;
    }
    .gest-col-2 {
        justify-self: start;
        color: #A8A9AD;
        width: 100%;
        margin-top: 20px;
    }
    .row-gest-h1 {
        margin-top: 5px;
        margin-bottom: 0px;
        font-size: 24px;
        line-height: 32px;
    }
    .my-role {
        margin-top: 15px;
        padding: 0px;
        margin-bottom: 0;
    }
    .my-role-list {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 0px;
    }
    .client {
        margin-top: 15px;
        padding: 0px;
        margin-bottom: 0;
    }
    .year {
        margin-top: 15px;
        padding: 0px;
        margin-bottom: 0;
    }
    .project-in-video {
        margin: 0;
        padding: 0;
        vertical-align: top;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .videoWrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
    }
    .list-project-info-text {
        font-size: 16px;
        line-height: 26px;
        color: #ffffff;
        font-weight: 300;
        margin-bottom: 12px;
    }
    li {
        list-style: none;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
    }
    .row-h4 {
        letter-spacing: 3px;
        margin-top: 0px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 300;
        margin-bottom: 9px;
    }
}