.main-genieai {
    color: white;
    padding-top: 3em;
    margin-bottom: 100px;
    bottom: 0;
    width: 100%;
    z-index: 1;
    padding: 0 calc(50% - 700px);
    
}

/* .row-gest-one {
    display: grid;
    grid-template-columns: 500px 900px;
   
    margin-top: 200px;
    justify-content: space-between;
} */

/* .row-gest-h1 {
    margin-top: 5px;
    margin-bottom: 0px;
    font-size: 46px;
    line-height: 50px;
} */

/* .gest-col {
    padding: 0;
    width: 320px;
    animation: HeroAnimation;
    animation-duration: 3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
} */


/* .gest-col-2 {
    justify-self: end;
    color: #A8A9AD;
    width: 720px;
    animation: HeroAnimation;
    animation-duration: 3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
} */

/* .row-h4 {
    letter-spacing: 4px;
    margin-top: 0px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    margin-bottom: 9px;
} */

.list-unstyled-1 {
    font-size: 17px;
    color: #888A8E;
    list-style: none;
    padding: 0;
    width: 405px;
    font-weight: 300;
    line-height: 24px;
}

.span1 {
    color: #888A8E;
}

.contact-1 {
    color: #ffffff;
}


.list-unstyled-2 {
    font-size: 17px;
    color: #ffffff;
    list-style: none;
    padding: 0;
    font-weight: 300;
    line-height: 24px;
}

.list-unstyled-3 {
    font-size: 17px;
    color: #888A8E;
    list-style: none;
    padding: 0;
    font-weight: 300;
    line-height: 24px;
}

.list-project-info {
    right: 0;
    list-style: none;
    padding: 0;
    margin: 0;
}
/* 
.list-project-info-text {
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    font-weight: 300;
    margin-bottom: 30px;

} */


.footer-link {
    align-content: center;
    color: #ffffff;
    position: relative;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
  }
  
  .footer-link:after {
    display: block;
    position: absolute;
    content: "";
    background-color: #ffffff;
    height: 3px;
    width: 0%;
    left: 50%;
    -webkit-transition: width .3s ease-in-out;
    -moz--transition: width .3s ease-in-out;
    transition: width .3s ease-in-out;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
     
  }
  
  .footer-link:hover:after{
    align-self: center;
    width: 100%;
  }
  
  .footer-link:focus:after{
    right: 50%;
  }
/* 
.client {
    margin-top: 38px;
    padding: 0px;
    margin-bottom: 0;
}
.my-role {
    margin-top: 38px;
    padding: 0px;
    margin-bottom: 0;
}
.year {
    margin-top: 38px;
    padding: 0px;
    margin-bottom: 0;
} */



/* li {
    list-style: none;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
} */

.genieai-images {
    margin-top: 50px;
    margin-bottom: 50px;
}
