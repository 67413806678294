
.contact-page {
    height: 100vh;
    width: 100%;
    // background-image: url('https://images.pexels.com/photos/3672776/pexels-photo-3672776.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
    background-image: url('..//images/map.jpg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;
}


// .contact-form {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;

// }


.sub-title{
    color: white;
    text-align: center;
    font-size: 1.5rem;

}

#contact-container {
    box-sizing: content-box;
    width: 1100px; 
 display: flex;
 border-radius: 15px;
 overflow: hidden;
 padding: 10px;
 backdrop-filter: blur(5px);
 border: 1px solid rgba(255,255,255,.2);
 -webkit-box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
 -moz-box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
 box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
 animation: HeroAnimation;
 animation-duration: 3s;
 animation-delay: 0.1s;
 animation-fill-mode: forwards;
 animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
     .contact-info{
     background-color: black;
     border-radius: 15px;
     flex: 0 1 40%;
     padding: 40px;
     color: white;
     display: flex;
     flex-direction: column;
     .contact-text{
         font-size: 32px;
         line-height: 42px;
         font-weight: 500;
        //  margin-top: 0;
         margin: 0;
     }
     .contact-fill{
         color: rgba(255,255,255,.7);
         font-size: 18px;
         line-height: 28px;
         font-weight: 300;
         margin-bottom: 2rem;
     }
    .icon-text{
        
         text-align: center;
         display: flex;
         justify-content: flex-start;
         align-items: center;
         margin-bottom: 2rem;
         .fa{
            width: 30px;
             text-align: center;
             margin-right: 20px;
            //  flex: 0 0 50px;
             font-size: 1.8rem;
             color: white;
         }
         span{
             font-size: 18px;
             font-weight: 300;
         }
     }
     .social-media {
        display: flex;
        width: 50%;
        justify-content: space-between;
         .icon-circle{
            text-decoration: none;
            flex: 0 0 40px;
            margin-top: 72px;
            margin-right: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            border-radius: 50%;
            background:  black;
            color: white;
            font-size: 25px;
            transition: .3s all ease-in-out;
            &:hover{
                color: black;
                background: white;
                
            }
            // .fa{
            //     color: white;
            //     font-size: 25px;
            // }
         }
     }
 }
 form{
     padding: 40px;
     width: 100%;
     .col{
         display: flex;
         width: 100%;
         flex: 0 0 100%;
         
     }
     .form-group{
         display: flex;
         justify-content: flex-start;
         flex-direction: column;
         flex: 0 1 100%;
         padding-right: 40px;
    
    .validation {
        display: flex;
    }
    .validation-error{
        color:rgb(255, 83, 83);
        font-size: 18px;
        margin-left: 5px;
    }

     label{
         font-size: 18px;
         color: white;
         margin-bottom: 1rem;
         color: rgba(255,255,255,.6);
         margin-bottom: 5px;
         }
         
         
         input[type="text"], input[type="email"], input[type="tel"]{
            //  background: rgba(255,255,255,.2);
            box-sizing: content-box;
             resize: none;
             border: none;
             border-radius: 0;
             cursor: pointer;
             padding: 10px;
             font-size: 1.5rem;
             font-weight: 300;
             color: white;
             font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
             background: transparent;
             border-bottom: 2px solid rgba(255,255,255,.4);
             transition: all .3s ease-in-out;
            margin-bottom: 2rem;
             &:focus{
                 border: none;
                 outline: none;
                 border-bottom: 2px solid white;
             }
            
         }
         &:nth-child(2){
             padding-right: 0;
         }
         #radio-buttons{
             display: flex;
             justify-content: flex-start;
             margin-top: 1rem;
             margin-bottom: 2rem;
             .radio-button{
                border-radius: 100%;
                margin-right: 16px;
                position: relative;
                .rad1::before,
                .rad1::after {
                  content: "";
                  border: 1px solid red;
                  display: block;
                  width: inherit;
                  height: inherit;
                  border-radius: inherit;
                  position: absolute;
                  transform: scale(0);
                  transform-origin: center center;
                }
                
                .rad1:before {
                  opacity: 1;
                  transition: .3s;
                }
                
                .rad1::after {
                  background: red;
                  opacity: .4;
                  transition: .6s;
                }
             }
             label{
                 margin-left: 6px;
                 font-size: 1.2rem;

             }
             input[type="radio"]:checked +label{
                color: white;
                // font-weight: 700;
             }
         }
         &.solo{
             padding: 0;
         }
         &.right{
             align-items: flex-end;
         }
         
         button{
             font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Cantarell,;
             letter-spacing: 1px;
             font-weight: 400;
             font-size: 18px;
             width: 100%;
             padding-right: 20px;
             padding-left: 20px;
             height: 50px;
             cursor: pointer;
             background: white;
             border: none;
             border-radius: 5px;
             transition: 0.3s ease-in-out;
             &:hover{
                background: #464646;
                color: white;
                outline: none;
             }

         }
     }
 }
 textarea{
    background: transparent;
    margin-top: 10px;
    border: none;
    border-radius: 0;
    resize: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    // height: 45px;
    font-weight: 300;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border-bottom: 2px solid rgba(255,255,255,.4);
    transition: all .3s ease-in-out;
    margin-bottom: 2rem;
    &:focus{
        border: none;
        outline: none;
        border-bottom: 2px solid white;
    }
    
 }
}


[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 18px;
    display: inline-block;
    color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #393939;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.buttonwithtext {
    display: grid;
    justify-items: center;
    grid-template-columns: auto auto;

    .sucess-msg{
        font-weight: bold;
        margin-right: 30px;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        line-height: 50px;
        font-size: 18px;
        align-content: center;
    }
    .err-msg{
     font-weight: bold;
     margin-right: 30px;
     margin-top: 0;
     margin-bottom: 0;
     padding: 0;
     line-height: 50px;
     font-size: 18px;
     align-content: center;
     color: rgb(255, 83, 83);
    }
}


@media (max-width: 1699px) {

    #contact-container {
        box-sizing: content-box;
        width: 1060px; 
     display: flex;
     border-radius: 15px;
     overflow: hidden;
     padding: 10px;
     backdrop-filter: blur(5px);
     border: 1px solid rgba(255,255,255,.2);
     -webkit-box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
     -moz-box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
     box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
     animation: HeroAnimation;
     animation-duration: 3s;
     animation-delay: 0.1s;
     animation-fill-mode: forwards;
     animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
         .contact-info{
         background-color: black;
         border-radius: 15px;
         flex: 0 1 40%;
         padding: 40px;
         color: white;
         display: flex;
         flex-direction: column;
         .contact-text{
             font-size: 32px;
             line-height: 42px;
             font-weight: 500;
            //  margin-top: 0;
             margin: 0;
         }
         .contact-fill{
             color: rgba(255,255,255,.7);
             font-size: 18px;
             line-height: 28px;
             font-weight: 300;
             margin-bottom: 2rem;
         }
        .icon-text{
            
             text-align: center;
             display: flex;
             justify-content: flex-start;
             align-items: center;
             margin-bottom: 2rem;
             .fa{
                width: 30px;
                 text-align: center;
                 margin-right: 20px;
                //  flex: 0 0 50px;
                 font-size: 1.8rem;
                 color: white;
             }
             span{
                 font-size: 18px;
                 font-weight: 300;
             }
         }
         .social-media {
            display: flex;
            width: 50%;
            justify-content: space-between;
             .icon-circle{
                text-decoration: none;
                flex: 0 0 40px;
                margin-top: 72px;
                margin-right: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;
                border-radius: 50%;
                background:  black;
                color: white;
                font-size: 25px;
                transition: .3s all ease-in-out;
                &:hover{
                    color: black;
                    background: white;
                    
                }
                // .fa{
                //     color: white;
                //     font-size: 25px;
                // }
             }
         }
     }
     form{
         padding: 40px;
         width: 100%;
         .col{
             display: flex;
             width: 100%;
             flex: 0 0 100%;
             
         }
         .form-group{
             display: flex;
             justify-content: flex-start;
             flex-direction: column;
             flex: 0 1 100%;
             padding-right: 40px;
            
         label{
             font-size: 18px;
             color: white;
             margin-bottom: 1rem;
             color: rgba(255,255,255,.6);
             margin-bottom: 5px;
             }
             
             
             input[type="text"], input[type="email"], input[type="tel"]{
                //  background: rgba(255,255,255,.2);
                box-sizing: content-box;
                 resize: none;
                 border: none;
                 cursor: pointer;
                 padding: 10px;
                 font-size: 1.5rem;
                 font-weight: 300;
                 color: white;
                 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                 background: transparent;
                 border-bottom: 2px solid rgba(255,255,255,.4);
                 transition: all .3s ease-in-out;
                margin-bottom: 2rem;
                 &:focus{
                     border: none;
                     outline: none;
                     border-bottom: 2px solid white;
                 }
                
             }
             &:nth-child(2){
                 padding-right: 0;
             }
             #radio-buttons{
                 display: flex;
                 justify-content: flex-start;
                 margin-top: 1rem;
                 margin-bottom: 2rem;
                 .radio-button{
                    border-radius: 100%;
                    margin-right: 16px;
                    position: relative;
                    .rad1::before,
                    .rad1::after {
                      content: "";
                      border: 1px solid red;
                      display: block;
                      width: inherit;
                      height: inherit;
                      border-radius: inherit;
                      position: absolute;
                      transform: scale(0);
                      transform-origin: center center;
                    }
                    
                    .rad1:before {
                      opacity: 1;
                      transition: .3s;
                    }
                    
                    .rad1::after {
                      background: red;
                      opacity: .4;
                      transition: .6s;
                    }
                 }
                 label{
                     margin-left: 6px;
                     font-size: 1.2rem;
    
                 }
                 input[type="radio"]:checked +label{
                    color: white;
                    // font-weight: 700;
                 }
             }
             &.solo{
                 padding: 0;
             }
             &.right{
                 align-items: flex-end;
             }
             
             button{
                 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Cantarell,;
                 letter-spacing: 1px;
                 font-weight: 400;
                 font-size: 18px;
                 width: 200px;
                 height: 50px;
                 cursor: pointer;
                 background: white;
                 border: none;
                 border-radius: 5px;
                 transition: 0.3s ease-in-out;
                 &:hover{
                    background: #464646;
                    color: white;
                    outline: none;
                 }
    
             }
         }
     }
     textarea{
        background: transparent;
        margin-top: 10px;
        border: none;
        resize: none;
        border: none;
        cursor: pointer;
        font-size: 1.5rem;
        // height: 45px;
        font-weight: 300;
        color: white;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        border-bottom: 2px solid rgba(255,255,255,.4);
        transition: all .3s ease-in-out;
        margin-bottom: 2rem;
        &:focus{
            border: none;
            outline: none;
            border-bottom: 2px solid white;
        }
        
     }
    }
    

  }
@media (max-width: 1400px) {

    #contact-container {
        box-sizing: content-box;
        width: 900px; 
     display: flex;
     border-radius: 15px;
     overflow: hidden;
     padding: 10px;
     backdrop-filter: blur(5px);
     border: 1px solid rgba(255,255,255,.2);
     -webkit-box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
     -moz-box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
     box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
     animation: HeroAnimation;
     animation-duration: 3s;
     animation-delay: 0.1s;
     animation-fill-mode: forwards;
     animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
         .contact-info{
         background-color: black;
         border-radius: 15px;
         flex: 0 1 40%;
         padding: 14px;
         color: white;
         display: flex;
         flex-direction: column;
         .contact-text{
             font-size: 32px;
             line-height: 42px;
             font-weight: 500;
            //  margin-top: 0;
             margin: 0;
         }
         .contact-fill{
             color: rgba(255,255,255,.7);
             font-size: 18px;
             line-height: 28px;
             font-weight: 300;
             margin-bottom: 2rem;
         }
        .icon-text{
            
             text-align: center;
             display: flex;
             justify-content: flex-start;
             align-items: center;
             margin-bottom: 1rem;
             .fa{
                width: 30px;
                 text-align: center;
                 margin-right: 20px;
                //  flex: 0 0 50px;
                 font-size: 1.4rem;
                 color: white;
             }
             span{
                 font-size: 16px;
                 font-weight: 300;
             }
         }
         .social-media {
            display: flex;
            width: 50%;
            justify-content: space-between;
             .icon-circle{
                text-decoration: none;
                flex: 0 0 40px;
                margin-top: 72px;
                margin-right: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;
                border-radius: 50%;
                background:  black;
                color: white;
                font-size: 25px;
                transition: .3s all ease-in-out;
                &:hover{
                    color: black;
                    background: white;
                    
                }
                // .fa{
                //     color: white;
                //     font-size: 25px;
                // }
             }
         }
     }
     form{
         padding: 20px;
         width: 100%;
         .col{
             display: flex;
             width: 100%;
             flex: 0 0 100%;
             
         }
         .form-group{
             display: flex;
             justify-content: flex-start;
             flex-direction: column;
             flex: 0 1 100%;
             padding-right: 30px;
            
         label{
             font-size: 18px;
             color: white;
             margin-bottom: 1rem;
             color: rgba(255,255,255,.6);
             margin-bottom: 5px;
             }
             
             
             input[type="text"], input[type="email"], input[type="tel"]{
                //  background: rgba(255,255,255,.2);
                box-sizing: content-box;
                 resize: none;
                 border: none;
                 cursor: pointer;
                 padding: 4px;
                 font-size: 1.5rem;
                 font-weight: 300;
                 color: white;
                 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                 background: transparent;
                 border-bottom: 2px solid rgba(255,255,255,.4);
                 transition: all .3s ease-in-out;
                margin-bottom: 2rem;
                 &:focus{
                     border: none;
                     outline: none;
                     border-bottom: 2px solid white;
                 }
                
             }
             &:nth-child(2){
                 padding-right: 0;
             }
             #radio-buttons{
                 display: flex;
                 justify-content: flex-start;
                 margin-top: 1rem;
                 margin-bottom: 2rem;
                 .radio-button{
                    border-radius: 100%;
                    margin-right: 16px;
                    position: relative;
                    .rad1::before,
                    .rad1::after {
                      content: "";
                      border: 1px solid red;
                      display: block;
                      width: inherit;
                      height: inherit;
                      border-radius: inherit;
                      position: absolute;
                      transform: scale(0);
                      transform-origin: center center;
                    }
                    
                    .rad1:before {
                      opacity: 1;
                      transition: .3s;
                    }
                    
                    .rad1::after {
                      background: red;
                      opacity: .4;
                      transition: .6s;
                    }
                 }
                 label{
                     margin-left: 6px;
                     font-size: 1.2rem;
    
                 }
                 input[type="radio"]:checked +label{
                    color: white;
                    // font-weight: 700;
                 }
             }
             &.solo{
                 padding: 0;
             }
             &.right{
                 align-items: flex-end;
             }
             
             button{
                 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Cantarell,;
                 letter-spacing: 1px;
                 font-weight: 400;
                 font-size: 18px;
                 width: 200px;
                 height: 45px;
                 cursor: pointer;
                 background: white;
                 border: none;
                 border-radius: 5px;
                 transition: 0.3s ease-in-out;
                 &:hover{
                    background: #464646;
                    color: white;
                    outline: none;
                 }
    
             }
         }
     }
     textarea{
        background: transparent;
        margin-top: 10px;
        border: none;
        resize: none;
        border: none;
        cursor: pointer;
        font-size: 1.2rem;
        // height: 45px;
        font-weight: 300;
        color: white;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        border-bottom: 2px solid rgba(255,255,255,.4);
        transition: all .3s ease-in-out;
        margin-bottom: 2rem;
        &:focus{
            border: none;
            outline: none;
            border-bottom: 2px solid white;
        }
        
     }
    }
    

  }

  @media (max-width: 1199px) {

    #contact-container {
        box-sizing: content-box;
        width: 800px;
        // height: 400px;
     display: flex;
     border-radius: 15px;
     overflow: hidden;
     padding: 10px;
     backdrop-filter: blur(5px);
     border: 1px solid rgba(255,255,255,.2);
     -webkit-box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
     -moz-box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
     box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
     animation: HeroAnimation;
     animation-duration: 3s;
     animation-delay: 0.1s;
     animation-fill-mode: forwards;
     animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
         .contact-info{
         background-color: black;
         border-radius: 15px;
         flex: 0 1 40%;
         padding: 10px;
         color: white;
         display: flex;
         flex-direction: column;
         .contact-text{
             font-size: 22px;
             line-height: 32px;
             font-weight: 500;
            //  margin-top: 0;
             margin: 0;
         }
         .contact-fill{
             color: rgba(255,255,255,.7);
             font-size: 16px;
             line-height: 28px;
             font-weight: 300;
             margin-bottom: 1rem;
         }
        .icon-text{
            
             text-align: center;
             display: flex;
             justify-content: flex-start;
             align-items: center;
             margin-bottom: 1rem;
             .fa{
                width: 20px;
                 text-align: center;
                 margin-right: 14px;
                //  flex: 0 0 50px;
                 font-size: 20px;
                 color: white;
             }
             span{
                 font-size: 16px;
                 font-weight: 300;
             }
         }
         .social-media {
            display: flex;
            width: 50%;
            justify-content: space-between;
             .icon-circle{
                text-decoration: none;
                flex: 0 0 30px;
                margin-top: 22px;
                margin-right: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 30px;
                border-radius: 50%;
                background:  black;
                color: white;
                font-size: 20px;
                transition: .3s all ease-in-out;
                &:hover{
                    color: black;
                    background: white;
                    
                }
                // .fa{
                //     color: white;
                //     font-size: 25px;
                // }
             }
         }
     }
     form{
         padding-top: 15px;
         padding-right: 15px;
         padding-bottom: 10px;
         width: 100%;
         .col{
             display: flex;
             width: 100%;
             flex: 0 0 100%;
             margin-bottom: 0px;
             
         }
         .form-group{
             display: flex;
             justify-content: flex-start;
             flex-direction: column;
             flex: 0 1 100%;
             padding-right: 30px;
             margin-bottom: 0px;
             .validation-error{
                color:rgb(255, 83, 83);
                font-size: 16px;
                margin-left: 5px;
            }
         label{
             font-size: 16px;
             color: white;
             color: rgba(255,255,255,.6);
             margin-bottom: 10px;
             }
             
             
             input[type="text"], input[type="email"], input[type="tel"]{
                //  background: rgba(255,255,255,.2);
                box-sizing: content-box;
                 resize: none;
                 border: none;
                 cursor: pointer;
                 padding: 4px;
                 font-size: 16px;
                 font-weight: 300;
                 color: white;
                 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                 background: transparent;
                 border-bottom: 2px solid rgba(255,255,255,.4);
                 transition: all .3s ease-in-out;
                margin-bottom: 16px;
                 &:focus{
                     border: none;
                     outline: none;
                     border-bottom: 2px solid white;
                 }
                
             }
             &:nth-child(2){
                 padding-right: 0;
             }
             #radio-buttons{
                 display: flex;
                 justify-content: flex-start;
                 margin-top: 1rem;
                 margin-bottom: 1rem;
                 .radio-button{     
                    border-radius: 100%;
                    margin-right: 16px;
                    position: relative;
                    .rad1::before,
                    .rad1::after {
                      content: "";
                      border: 1px solid red;
                      display: block;
                      width: inherit;
                      height: inherit;
                      border-radius: inherit;
                      position: absolute;
                      transform: scale(0);
                      transform-origin: center center;
                    }
                    // .rad1 {
                    //    font-size: 32px;
                    //   }

                    .rad1:before {
                      opacity: 1;
                      transition: .3s;
                    }
                    
                    .rad1::after {
                      background: red;
                      opacity: .4;
                      transition: .6s;
                    }
                 }
                 label{
                     margin-left: 0px;
                     font-size: 16px;
                     line-height: 16px;
    
                 }
                 input[type="radio"]:checked +label{
                    color: white;
                    // font-weight: 700;
                 }
             }
             &.solo{
                 padding: 0;
             }
             &.right{
                 align-items: flex-end;
             }
             
             button{
                 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Cantarell,;
                 letter-spacing: 1px;
                 font-weight: 400;
                 font-size: 16px;
                 width: 170px;
                 height: 40px;
                 cursor: pointer;
                 background: white;
                 border: none;
                 border-radius: 5px;
                 transition: 0.3s ease-in-out;
                 &:hover{
                    background: #464646;
                    color: white;
                    outline: none;
                 }
    
             }
         }
     }
     textarea{
        background: transparent;
        margin-top: 0px;
        border: none;
        resize: none;
        border: none;
        cursor: pointer;
        font-size: 16px;
        // height: 45px;
        font-weight: 300;
        color: white;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        border-bottom: 2px solid rgba(255,255,255,.4);
        transition: all .3s ease-in-out;
        margin-bottom: 1rem;
        &:focus{
            border: none;
            outline: none;
            border-bottom: 2px solid white;
        }
        
     }
    }
    

  }

  @media (max-width: 969px) {

    #contact-container {
        box-sizing: content-box;
        width: 760px;
        // height: 400px;
     display: flex;
     border-radius: 15px;
     overflow: hidden;
     padding: 10px;
     backdrop-filter: blur(5px);
     border: 1px solid rgba(255,255,255,.2);
     -webkit-box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
     -moz-box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
     box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
     animation: HeroAnimation;
     animation-duration: 3s;
     animation-delay: 0.1s;
     animation-fill-mode: forwards;
     animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
         .contact-info{
         background-color: black;
         border-radius: 15px;
         flex: 0 1 40%;
         padding: 10px;
         color: white;
         display: flex;
         flex-direction: column;
         .contact-text{
             font-size: 22px;
             line-height: 32px;
             font-weight: 500;
            //  margin-top: 0;
             margin: 0;
         }
         .contact-fill{
             color: rgba(255,255,255,.7);
             font-size: 16px;
             line-height: 28px;
             font-weight: 300;
             margin-bottom: 1rem;
         }
        .icon-text{
            
             text-align: center;
             display: flex;
             justify-content: flex-start;
             align-items: center;
             margin-bottom: 1rem;
             .fa{
                width: 20px;
                 text-align: center;
                 margin-right: 14px;
                //  flex: 0 0 50px;
                 font-size: 20px;
                 color: white;
             }
             span{
                 font-size: 16px;
                 font-weight: 300;
             }
         }
         .social-media {
            display: flex;
            width: 50%;
            justify-content: space-between;
             .icon-circle{
                text-decoration: none;
                flex: 0 0 30px;
                margin-top: 22px;
                margin-right: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 30px;
                border-radius: 50%;
                background:  black;
                color: white;
                font-size: 20px;
                transition: .3s all ease-in-out;
                &:hover{
                    color: black;
                    background: white;
                    
                }
                // .fa{
                //     color: white;
                //     font-size: 25px;
                // }
             }
         }
     }
     form{
         padding-top: 15px;
         padding-right: 15px;
         padding-bottom: 10px;
         width: 100%;
         .col{
             display: flex;
             width: 100%;
             flex: 0 0 100%;
             margin-bottom: 0px;
             
         }
         .form-group{
             display: flex;
             justify-content: flex-start;
             flex-direction: column;
             flex: 0 1 100%;
             padding-right: 30px;
             margin-bottom: 0px;
            
         label{
             font-size: 16px;
             color: white;
             color: rgba(255,255,255,.6);
             margin-bottom: 10px;
             }
             
             
             input[type="text"], input[type="email"], input[type="tel"]{
                //  background: rgba(255,255,255,.2);
                box-sizing: content-box;
                 resize: none;
                 border: none;
                 cursor: pointer;
                 padding: 4px;
                 font-size: 16px;
                 font-weight: 300;
                 color: white;
                 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                 background: transparent;
                 border-bottom: 2px solid rgba(255,255,255,.4);
                 transition: all .3s ease-in-out;
                margin-bottom: 16px;
                 &:focus{
                     border: none;
                     outline: none;
                     border-bottom: 2px solid white;
                 }
                
             }
             &:nth-child(2){
                 padding-right: 0;
             }
             #radio-buttons{
                 display: flex;
                 justify-content: flex-start;
                 margin-top: 1rem;
                 margin-bottom: 1rem;
                 .radio-button{     
                    border-radius: 100%;
                    margin-right: 16px;
                    position: relative;
                    .rad1::before,
                    .rad1::after {
                      content: "";
                      border: 1px solid red;
                      display: block;
                      width: inherit;
                      height: inherit;
                      border-radius: inherit;
                      position: absolute;
                      transform: scale(0);
                      transform-origin: center center;
                    }
                    // .rad1 {
                    //    font-size: 32px;
                    //   }

                    .rad1:before {
                      opacity: 1;
                      transition: .3s;
                    }
                    
                    .rad1::after {
                      background: red;
                      opacity: .4;
                      transition: .6s;
                    }
                 }
                 label{
                     margin-left: 0px;
                     font-size: 16px;
                     line-height: 16px;
    
                 }
                 input[type="radio"]:checked +label{
                    color: white;
                    // font-weight: 700;
                 }
             }
             &.solo{
                 padding: 0;
             }
             &.right{
                 align-items: flex-end;
             }
             
             button{
                 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Cantarell,;
                 letter-spacing: 1px;
                 font-weight: 400;
                 font-size: 16px;
                 width: 170px;
                 height: 40px;
                 cursor: pointer;
                 background: white;
                 border: none;
                 border-radius: 5px;
                 transition: 0.3s ease-in-out;
                 &:hover{
                    background: #464646;
                    color: white;
                    outline: none;
                 }
    
             }
         }
     }
     textarea{
        background: transparent;
        margin-top: 0px;
        border: none;
        resize: none;
        border: none;
        cursor: pointer;
        font-size: 16px;
        // height: 45px;
        font-weight: 300;
        color: white;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        border-bottom: 2px solid rgba(255,255,255,.4);
        transition: all .3s ease-in-out;
        margin-bottom: 1rem;
        &:focus{
            border: none;
            outline: none;
            border-bottom: 2px solid white;
        }
     }
    }
  }

  @media (max-width: 819px) {

    #contact-container {
        box-sizing: content-box;
        width: 720px;
        // height: 400px;
     display: flex;
     border-radius: 15px;
     overflow: hidden;
     padding: 10px;
     backdrop-filter: blur(5px);
     border: 1px solid rgba(255,255,255,.2);
     -webkit-box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
     -moz-box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
     box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
     animation: HeroAnimation;
     animation-duration: 3s;
     animation-delay: 0.1s;
     animation-fill-mode: forwards;
     animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
         .contact-info{
         background-color: black;
         border-radius: 15px;
         flex: 0 1 40%;
         padding: 10px;
         color: white;
         display: flex;
         flex-direction: column;
         .contact-text{
             font-size: 22px;
             line-height: 32px;
             font-weight: 500;
            //  margin-top: 0;
             margin: 0;
         }
         .contact-fill{
             color: rgba(255,255,255,.7);
             font-size: 16px;
             line-height: 28px;
             font-weight: 300;
             margin-bottom: 1rem;
         }
        .icon-text{
            
             text-align: center;
             display: flex;
             justify-content: flex-start;
             align-items: center;
             margin-bottom: 1rem;
             .fa{
                width: 20px;
                 text-align: center;
                 margin-right: 14px;
                //  flex: 0 0 50px;
                 font-size: 20px;
                 color: white;
             }
             span{
                 font-size: 16px;
                 font-weight: 300;
             }
         }
         .social-media {
            display: flex;
            width: 50%;
            justify-content: space-between;
             .icon-circle{
                text-decoration: none;
                flex: 0 0 30px;
                margin-top: 22px;
                margin-right: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 30px;
                border-radius: 50%;
                background:  black;
                color: white;
                font-size: 20px;
                transition: .3s all ease-in-out;
                &:hover{
                    color: black;
                    background: white;
                    
                }
                // .fa{
                //     color: white;
                //     font-size: 25px;
                // }
             }
         }
     }
     form{
         padding-top: 15px;
         padding-right: 15px;
         padding-bottom: 10px;
         width: 100%;
         .col{
             display: flex;
             width: 100%;
             flex: 0 0 100%;
             margin-bottom: 0px;
             
         }
         .form-group{
             display: flex;
             justify-content: flex-start;
             flex-direction: column;
             flex: 0 1 100%;
             padding-right: 30px;
             margin-bottom: 0px;
            
         label{
             font-size: 16px;
             color: white;
             color: rgba(255,255,255,.6);
             margin-bottom: 10px;
             }
             
             
             input[type="text"], input[type="email"], input[type="tel"]{
                //  background: rgba(255,255,255,.2);
                box-sizing: content-box;
                 resize: none;
                 border: none;
                 cursor: pointer;
                 padding: 4px;
                 font-size: 16px;
                 font-weight: 300;
                 color: white;
                 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                 background: transparent;
                 border-bottom: 2px solid rgba(255,255,255,.4);
                 transition: all .3s ease-in-out;
                margin-bottom: 16px;
                 &:focus{
                     border: none;
                     outline: none;
                     border-bottom: 2px solid white;
                 }
                
             }
             &:nth-child(2){
                 padding-right: 0;
             }
             #radio-buttons{
                 display: flex;
                 justify-content: flex-start;
                 margin-top: 1rem;
                 margin-bottom: 1rem;
                 .radio-button{     
                    border-radius: 100%;
                    margin-right: 16px;
                    position: relative;
                    .rad1::before,
                    .rad1::after {
                      content: "";
                      border: 1px solid red;
                      display: block;
                      width: inherit;
                      height: inherit;
                      border-radius: inherit;
                      position: absolute;
                      transform: scale(0);
                      transform-origin: center center;
                    }
                    // .rad1 {
                    //    font-size: 32px;
                    //   }

                    .rad1:before {
                      opacity: 1;
                      transition: .3s;
                    }
                    
                    .rad1::after {
                      background: red;
                      opacity: .4;
                      transition: .6s;
                    }
                 }
                 label{
                     margin-left: 0px;
                     font-size: 16px;
                     line-height: 16px;
    
                 }
                 input[type="radio"]:checked +label{
                    color: white;
                    // font-weight: 700;
                 }
             }
             &.solo{
                 padding: 0;
             }
             &.right{
                 align-items: flex-end;
             }
             
             button{
                 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Cantarell,;
                 letter-spacing: 1px;
                 font-weight: 400;
                 font-size: 16px;
                 width: 170px;
                 height: 40px;
                 cursor: pointer;
                 background: white;
                 border: none;
                 border-radius: 5px;
                 transition: 0.3s ease-in-out;
                 &:hover{
                    background: #464646;
                    color: white;
                    outline: none;
                 }
    
             }
         }
     }
     textarea{
        background: transparent;
        margin-top: 0px;
        border: none;
        resize: none;
        border: none;
        cursor: pointer;
        font-size: 16px;
        // height: 45px;
        font-weight: 300;
        color: white;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        border-bottom: 2px solid rgba(255,255,255,.4);
        transition: all .3s ease-in-out;
        margin-bottom: 1rem;
        &:focus{
            border: none;
            outline: none;
            border-bottom: 2px solid white;
        }
     }
    }
  }

  @media (max-width: 749px) {

    #contact-container {
    box-sizing: content-box;
    display: flex;
    width: 500px;
     height: 690px;
     display: flex;
     flex-direction: column;
     border-radius: 15px;
     overflow: hidden;
     padding: 10px;
     backdrop-filter: blur(5px);
     border: 1px solid rgba(255,255,255,.2);
     -webkit-box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
     -moz-box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
     box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
     animation: HeroAnimation;
     animation-duration: 3s;
     animation-delay: 0.1s;
     animation-fill-mode: forwards;
     animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
         .contact-info{
         background-color: black;
         border-radius: 12px;
         flex: 0 1 40%;
         padding: 20px;
         color: white;
         display: flex;
         flex-direction: column;

         .contact-text{
             font-size: 22px;
             line-height: 32px;
             font-weight: 500;
            //  margin-top: 0;
             margin: 0;
         }
         .contact-fill{
             color: rgba(255,255,255,.7);
             font-size: 16px;
             line-height: 28px;
             font-weight: 300;
             margin-bottom: 1rem;
         }
        .icon-text{
            
             text-align: center;
             display: flex;
             justify-content: flex-start;
             align-items: center;
             margin-bottom: 1rem;
             .fa{
                width: 20px;
                 text-align: center;
                 margin-right: 14px;
                //  flex: 0 0 50px;
                 font-size: 20px;
                 color: white;
             }
             span{
                 font-size: 16px;
                 font-weight: 300;
             }
         }
         .social-media {
            display: flex;
            width: 50%;
            justify-content: space-between;
             .icon-circle{
                text-decoration: none;
                flex: 0 0 30px;
                margin-top: 22px;
                margin-right: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 30px;
                border-radius: 50%;
                background:  black;
                color: white;
                font-size: 20px;
                transition: .3s all ease-in-out;
                &:hover{
                    color: black;
                    background: white;
                    
                }
                // .fa{
                //     color: white;
                //     font-size: 25px;
                // }
             }
         }
     }
     form{
         padding-top: 25px;
         padding-right: 15px;
         padding-bottom: 10px;
         width: 100%;
         .col{
             display: flex;
             width: 100%;
             flex: 0 0 100%;
             margin-bottom: 0px;
             
         }
         .form-group{
             display: flex;
             justify-content: flex-start;
             flex-direction: column;
             flex: 0 1 100%;
             padding-right: 30px;
             margin-bottom: 0px;
            
         label{
             font-size: 16px;
             color: white;
             color: rgba(255,255,255,.6);
             margin-bottom: 10px;
             }
             
             
             input[type="text"], input[type="email"], input[type="tel"]{
                //  background: rgba(255,255,255,.2);
                box-sizing: content-box;
                 resize: none;
                 border: none;
                 cursor: pointer;
                 padding: 4px;
                 font-size: 16px;
                 font-weight: 300;
                 color: white;
                 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                 background: transparent;
                 border-bottom: 2px solid rgba(255,255,255,.4);
                 transition: all .3s ease-in-out;
                margin-bottom: 16px;
                 &:focus{
                     border: none;
                     outline: none;
                     border-bottom: 2px solid white;
                 }
                
             }
             &:nth-child(2){
                 padding-right: 0;
             }
             #radio-buttons{
                 display: flex;
                 justify-content: flex-start;
                 margin-top: 1rem;
                 margin-bottom: 1rem;
                 .radio-button{     
                    border-radius: 100%;
                    margin-right: 16px;
                    position: relative;
                    .rad1::before,
                    .rad1::after {
                      content: "";
                      border: 1px solid red;
                      display: block;
                      width: inherit;
                      height: inherit;
                      border-radius: inherit;
                      position: absolute;
                      transform: scale(0);
                      transform-origin: center center;
                    }
                    // .rad1 {
                    //    font-size: 32px;
                    //   }

                    .rad1:before {
                      opacity: 1;
                      transition: .3s;
                    }
                    
                    .rad1::after {
                      background: red;
                      opacity: .4;
                      transition: .6s;
                    }
                 }
                 label{
                     margin-left: 0px;
                     font-size: 16px;
                     line-height: 16px;
    
                 }
                 input[type="radio"]:checked +label{
                    color: white;
                    // font-weight: 700;
                 }
             }
             &.solo{
                 padding: 0;
             }
             &.right{
                 align-items: flex-end;
             }
             
             button{
                 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Cantarell,;
                 letter-spacing: 1px;
                 font-weight: 400;
                 font-size: 16px;
                 width: 170px;
                 height: 40px;
                 cursor: pointer;
                 background: white;
                 border: none;
                 border-radius: 5px;
                 transition: 0.3s ease-in-out;
                 &:hover{
                    background: #464646;
                    color: white;
                    outline: none;
                 }
    
             }
         }
     }
     textarea{
        background: transparent;
        margin-top: 0px;
        border: none;
        resize: none;
        border: none;
        cursor: pointer;
        font-size: 16px;
        // height: 45px;
        font-weight: 300;
        color: white;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        border-bottom: 2px solid rgba(255,255,255,.4);
        transition: all .3s ease-in-out;
        margin-bottom: 1rem;
        &:focus{
            border: none;
            outline: none;
            border-bottom: 2px solid white;
        }
     }
    }
  }

  @media (max-width: 599px) {
    .contact-page {
        height: 100%;
    }

    #contact-container {
    margin-top: 100px;
    margin-bottom: 80px;
    box-sizing: content-box;
    display: flex;
    width: 360px;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    overflow: hidden;
    padding: 10px;
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255,255,255,.2);
    -webkit-box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
    animation: HeroAnimation;
    animation-duration: 3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
    
        .contact-info{
        background-color: black;
        border-radius: 12px;
        flex: 0 1 40%;
        padding: 5px 10px 0px 10px;
        color: white;
        display: flex;
        flex-direction: column;

            .contact-text{
            font-size: 22px;
            line-height: 32px;
            font-weight: 500;
            //  margin-top: 0;
            margin: 0;
            }

            .contact-fill{
            color: rgba(255,255,255,.7);
            font-size: 16px;
            line-height: 16px;
            font-weight: 300;
            margin-top: 10px;
            margin-bottom: 15px;
            }

            .icon-text{
             text-align: center;
             display: flex;
             justify-content: flex-start;
             align-items: center;
             margin-bottom: 1rem;
                    
                .fa{
                width: 20px;
                text-align: center;
                margin-right: 14px;
                    //  flex: 0 0 50px;
                font-size: 20px;
                color: white;
                }

                span{
                font-size: 16px;
                font-weight: 300;
                }
         }


         .social-media {
            display: flex;
            width: 50%;
            justify-content: space-between;
             .icon-circle{
                text-decoration: none;
                flex: 0 0 30px;
                margin-top: 0px;
                margin-bottom: 10px;
                margin-right: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 30px;
                border-radius: 50%;
                background:  black;
                color: white;
                font-size: 20px;
                transition: .3s all ease-in-out;
                &:hover{
                    color: black;
                    background: white;
                    
                }
                // .fa{
                //     color: white;
                //     font-size: 25px;
                // }
             }
         }
     }
     form{
         padding-top: 15px;
         padding-right: 15px;
         padding-bottom: 10px;
         padding-left: 10px;
         width: 100%;
         .col{
             display: flex;
             flex-direction: column;
             width: 100%;
             flex: 0 0 100%;
             margin-bottom: 0px;
         }
         .form-group{
             display: flex;
             justify-content: flex-start;
             flex-direction: column;
             flex: 0 1 100%;
             padding-right: 0px;
             margin-bottom: 0px;
            
         label{
             font-size: 16px;
             color: white;
             color: rgba(255,255,255,.6);
             margin-bottom: 5px;
             }
             
             
             input[type="text"], input[type="email"], input[type="tel"]{
                //  background: rgba(255,255,255,.2);
                box-sizing: content-box;
                 resize: none;
                 border: none;
                 cursor: pointer;
                 padding: 2px;
                 font-size: 16px;
                 font-weight: 300;
                 color: white;
                 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                 background: transparent;
                 border-bottom: 2px solid rgba(255,255,255,.4);
                 transition: all .3s ease-in-out;
                margin-bottom: 16px;
                 &:focus{
                     border: none;
                     outline: none;
                     border-bottom: 2px solid white;
                 }
                
             }
             &:nth-child(2){
                 padding-right: 0;
             }
             #radio-buttons{
                 display: flex;
                 justify-content: flex-end;
                 align-items: center;
                 align-content: center;
                 margin-top: 1rem;
                 margin-bottom: 1rem;
                 .radio-button{     
                    display: flex;
                    flex-direction: raw;
                    justify-content: center;
                    border-radius: 100%;
                    margin-right: 12px;
                    position: relative;
                    // .rad1::before {
                    //     font-size: 165px;
                    // }
                    // .rad1::after {
                    //   content: "";
                    //   border: 1px solid red;
                    //   display: block;
                    //   width: inherit;
                    //   height: inherit;
                    //   border-radius: inherit;
                    //   position: absolute;
                    //   transform: scale(0);
                    //   transform-origin: center center;
                    // }
                    // .rad1 {
                    //    font-size: 32px;
                    //   }

                    // .rad1:before {
                    //     height: 100px;
                    // opacity: 1;
                    // transition: .3s;
                    // }
                    
                    // .rad1::after {
                    //     height: 100px;
                    //   background: red;
                    //   opacity: .4;
                    //   transition: .6s;
                    // }
                 }
                 label{
                     margin-left: 0px;
                     font-size: 16px;
                     line-height: 24px;
    
                 }
                 input[type="radio"]:checked +label{
                    color: white;
                    // font-weight: 700;
                 }
             }
             &.solo{
                 padding: 0;
             }
             &.right{
                 align-items: flex-end;
             }
             
             button{
                 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Cantarell,;
                 letter-spacing: 1px;
                 font-weight: 400;
                 font-size: 16px;
                 width: 170px;
                 height: 40px;
                 cursor: pointer;
                 background: white;
                 border: none;
                 border-radius: 5px;
                 transition: 0.3s ease-in-out;
                 &:hover{
                    background: #464646;
                    color: white;
                    outline: none;
                 }
    
             }
         }
     }
     textarea{
        background: transparent;
        margin-top: 0px;
        border: none;
        resize: none;
        border: none;
        cursor: pointer;
        font-size: 16px;
        // height: 45px;
        font-weight: 300;
        color: white;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        border-bottom: 2px solid rgba(255,255,255,.4);
        transition: all .3s ease-in-out;
        margin-bottom: 1rem;
        &:focus{
            border: none;
            outline: none;
            border-bottom: 2px solid white;
        }
     }
    }

    [type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 18px;
    display: inline-block;
    color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 4px;
    width: 16px;
    height: 16px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #393939;
    position: absolute;
    top: 6px;
    left: 2px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.buttonwithtext {
    display: grid;
    justify-items: center;
    grid-template-columns: auto auto;

    .sucess-msg{
        margin-right: 30px;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        line-height: 50px;
        font-size: 18px;
        align-content: center;
    }
    .err-msg{
     margin-right: 30px;
     margin-top: 0;
     margin-bottom: 0;
     padding: 0;
     line-height: 50px;
     font-size: 18px;
     align-content: center;
     color: rgb(255, 83, 83);
    }
}
  }



  @media (max-width: 413px) {
      .contact-page {
          height: 100%;
      }

    #contact-container {
        padding-top: 100px;
        padding-bottom: 100px;
    margin-top: 100px;
    margin-bottom: 50px;
    box-sizing: content-box;
    display: flex;
    width: 320px;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    overflow: hidden;
    padding: 10px;
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255,255,255,.2);
    -webkit-box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
    animation: HeroAnimation;
    animation-duration: 3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
    
        .contact-info{
        background-color: black;
        border-radius: 12px;
        flex: 0 1 40%;
        padding: 5px 10px 0px 10px;
        color: white;
        display: flex;
        flex-direction: column;

            .contact-text{
            font-size: 22px;
            line-height: 32px;
            font-weight: 500;
            //  margin-top: 0;
            margin: 0;
            }

            .contact-fill{
            color: rgba(255,255,255,.7);
            font-size: 16px;
            line-height: 24px;
            font-weight: 300;
            margin-top: 10px;
            margin-bottom: 15px;
            border-radius: 0;
            }

            .icon-text{
             text-align: center;
             display: flex;
             justify-content: flex-start;
             align-items: center;
             margin-bottom: 1rem;
                    
                .fa{
                width: 20px;
                text-align: center;
                margin-right: 14px;
                    //  flex: 0 0 50px;
                font-size: 20px;
                color: white;
                }

                span{
                font-size: 16px;
                font-weight: 300;
                }
         }


         .social-media {
            display: flex;
            width: 50%;
            justify-content: space-between;
             .icon-circle{
                text-decoration: none;
                flex: 0 0 30px;
                margin-top: 0px;
                margin-bottom: 10px;
                margin-right: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 30px;
                border-radius: 50%;
                background:  black;
                color: white;
                font-size: 20px;
                transition: .3s all ease-in-out;
                &:hover{
                    color: black;
                    background: white;
                    
                }
                // .fa{
                //     color: white;
                //     font-size: 25px;
                // }
             }
         }
     }
     form{
         padding-top: 15px;
         padding-right: 15px;
         padding-bottom: 10px;
         padding-left: 10px;
         width: 100%;
         .col{
             display: flex;
             flex-direction: column;
             width: 100%;
             flex: 0 0 100%;
             margin-bottom: 0px;
         }
         .form-group{
             display: flex;
             justify-content: flex-start;
             flex-direction: column;
             flex: 0 1 100%;
             padding-right: 0px;
             margin-bottom: 0px;
            
         label{
             font-size: 16px;
             color: white;
             color: rgba(255,255,255,.6);
             margin-bottom: 5px;
             }
             
             
             input[type="text"], input[type="email"], input[type="tel"]{
                //  background: rgba(255,255,255,.2);
                box-sizing: content-box;
                 resize: none;
                 border: none;
                 cursor: pointer;
                 padding: 2px;
                 font-size: 16px;
                 font-weight: 300;
                 color: white;
                 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                 background: transparent;
                 border-bottom: 2px solid rgba(255,255,255,.4);
                 transition: all .3s ease-in-out;
                margin-bottom: 16px;
                 &:focus{
                     border: none;
                     outline: none;
                     border-bottom: 2px solid white;
                 }
                
             }
             &:nth-child(2){
                 padding-right: 0;
             }
             #radio-buttons{
                 display: flex;
                 justify-content: flex-end;
                 align-items: center;
                 align-content: center;
                 margin-top: 1rem;
                 margin-bottom: 1rem;
                 .radio-button{     
                    display: flex;
                    flex-direction: raw;
                    justify-content: center;
                    border-radius: 100%;
                    margin-right: 12px;
                    position: relative;
                    // .rad1::before {
                    //     font-size: 165px;
                    // }
                    // .rad1::after {
                    //   content: "";
                    //   border: 1px solid red;
                    //   display: block;
                    //   width: inherit;
                    //   height: inherit;
                    //   border-radius: inherit;
                    //   position: absolute;
                    //   transform: scale(0);
                    //   transform-origin: center center;
                    // }
                    // .rad1 {
                    //    font-size: 32px;
                    //   }

                    // .rad1:before {
                    //     height: 100px;
                    // opacity: 1;
                    // transition: .3s;
                    // }
                    
                    // .rad1::after {
                    //     height: 100px;
                    //   background: red;
                    //   opacity: .4;
                    //   transition: .6s;
                    // }
                 }
                 label{
                     margin-left: 0px;
                     font-size: 16px;
                     line-height: 24px;
    
                 }
                 input[type="radio"]:checked +label{
                    color: white;
                    // font-weight: 700;
                 }
             }
             &.solo{
                 padding: 0;
             }
             &.right{
                 align-items: flex-end;
             }
             
             button{
                 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Cantarell,;
                 letter-spacing: 1px;
                 font-weight: 400;
                 font-size: 16px;
                 width: 100%;
                 height: 40px;
                 cursor: pointer;
                 background: white;
                 border: none;
                 border-radius: 5px;
                 transition: 0.3s ease-in-out;
                 &:hover{
                    background: #464646;
                    color: white;
                    outline: none;
                 }
    
             }
         }
     }
     textarea{
        background: transparent;
        margin-top: 0px;
        border: none;
        resize: none;
        border: none;
        cursor: pointer;
        font-size: 16px;
        // height: 45px;
        font-weight: 300;
        color: white;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        border-bottom: 2px solid rgba(255,255,255,.4);
        transition: all .3s ease-in-out;
        margin-bottom: 1rem;
        &:focus{
            border: none;
            outline: none;
            border-bottom: 2px solid white;
        }
     }
    }

    [type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 18px;
    display: inline-block;
    color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 4px;
    width: 16px;
    height: 16px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #393939;
    position: absolute;
    top: 6px;
    left: 2px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.buttonwithtext {
    display: grid;
    justify-items: center;
    grid-template-columns: auto auto;

    .sucess-msg{
        margin-right: 20px;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        line-height: 40px;
        font-size: 18px;
        align-content: center;
    }
    .err-msg{
     margin-right: 20px;
     margin-top: 0;
     margin-bottom: 0;
     padding: 0;
     line-height: 40px;
     font-size: 18px;
     align-content: center;
     color: rgb(255, 83, 83);
    }
}
  }

  @media (max-width: 359px) {
    .contact-page {
        height: 100%;
    }

  #contact-container {
      padding-top: 100px;
      padding-bottom: 100px;
  margin-top: 100px;
  margin-bottom: 50px;
  box-sizing: content-box;
  display: flex;
  width: 280px;
  height: 800px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  overflow: hidden;
  padding: 10px;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255,255,255,.2);
  -webkit-box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 33px 0px rgba(0,0,0,0.75);
  animation: HeroAnimation;
  animation-duration: 3s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  
      .contact-info{
      background-color: black;
      border-radius: 12px;
      flex: 0 1 40%;
      padding: 5px 10px 0px 10px;
      color: white;
      display: flex;
      flex-direction: column;

          .contact-text{
          font-size: 22px;
          line-height: 32px;
          font-weight: 500;
          //  margin-top: 0;
          margin: 0;
          }

          .contact-fill{
          color: rgba(255,255,255,.7);
          font-size: 16px;
          line-height: 24px;
          font-weight: 300;
          margin-top: 10px;
          margin-bottom: 15px;
          }

          .icon-text{
           text-align: center;
           display: flex;
           justify-content: flex-start;
           align-items: center;
           margin-bottom: 1rem;
                  
              .fa{
              width: 20px;
              text-align: center;
              margin-right: 14px;
                  //  flex: 0 0 50px;
              font-size: 20px;
              color: white;
              }

              span{
              font-size: 16px;
              font-weight: 300;
              }
       }


       .social-media {
          display: flex;
          width: 50%;
          justify-content: space-between;
           .icon-circle{
              text-decoration: none;
              flex: 0 0 30px;
              margin-top: 0px;
              margin-bottom: 10px;
              margin-right: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 30px;
              border-radius: 50%;
              background:  black;
              color: white;
              font-size: 20px;
              transition: .3s all ease-in-out;
              &:hover{
                  color: black;
                  background: white;
                  
              }
              // .fa{
              //     color: white;
              //     font-size: 25px;
              // }
           }
       }
   }
   form{
       padding-top: 15px;
       padding-right: 15px;
       padding-bottom: 10px;
       padding-left: 10px;
       width: 100%;
       .col{
           display: flex;
           flex-direction: column;
           width: 100%;
           flex: 0 0 100%;
           margin-bottom: 0px;
       }
       .form-group{
           display: flex;
           justify-content: flex-start;
           flex-direction: column;
           flex: 0 1 100%;
           padding-right: 0px;
           margin-bottom: 0px;
          
       label{
           font-size: 16px;
           color: white;
           color: rgba(255,255,255,.6);
           margin-bottom: 5px;
           }
           
           
           input[type="text"], input[type="email"], input[type="tel"]{
              //  background: rgba(255,255,255,.2);
              box-sizing: content-box;
               resize: none;
               border: none;
               cursor: pointer;
               padding: 2px;
               font-size: 16px;
               font-weight: 300;
               color: white;
               font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
               background: transparent;
               border-bottom: 2px solid rgba(255,255,255,.4);
               transition: all .3s ease-in-out;
              margin-bottom: 16px;
               &:focus{
                   border: none;
                   outline: none;
                   border-bottom: 2px solid white;
               }
              
           }
           &:nth-child(2){
               padding-right: 0;
           }
           #radio-buttons{
               display: flex;
               flex-direction: column;
               justify-content: flex-start;
               text-align: left;
               align-items: flex-start;
               align-content:left;
               margin-top: 1rem;
               margin-bottom: 1rem;
               .radio-button{     
                  display: flex;
                  flex-direction: raw;
                  justify-content: left;
                  border-radius: 100%;
                  margin-right: 12px;
                  position: relative;
                  // .rad1::before {
                  //     font-size: 165px;
                  // }
                  // .rad1::after {
                  //   content: "";
                  //   border: 1px solid red;
                  //   display: block;
                  //   width: inherit;
                  //   height: inherit;
                  //   border-radius: inherit;
                  //   position: absolute;
                  //   transform: scale(0);
                  //   transform-origin: center center;
                  // }
                  // .rad1 {
                  //    font-size: 32px;
                  //   }

                  // .rad1:before {
                  //     height: 100px;
                  // opacity: 1;
                  // transition: .3s;
                  // }
                  
                  // .rad1::after {
                  //     height: 100px;
                  //   background: red;
                  //   opacity: .4;
                  //   transition: .6s;
                  // }
               }
               label{
                   margin-left: 0px;
                   font-size: 16px;
                   line-height: 24px;
  
               }
               input[type="radio"]:checked +label{
                  color: white;
                  // font-weight: 700;
               }
           }
           &.solo{
               padding: 0;
           }
           &.right{
               align-items: flex-end;
           }
           
           button{
               font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Cantarell,;
               letter-spacing: 1px;
               font-weight: 400;
               font-size: 16px;
               width: 100%;
               height: 40px;
               cursor: pointer;
               background: white;
               border: none;
               border-radius: 5px;
               transition: 0.3s ease-in-out;
               &:hover{
                  background: #464646;
                  color: white;
                  outline: none;
               }
  
           }
       }
   }
   textarea{
      background: transparent;
      margin-top: 0px;
      border: none;
      resize: none;
      border: none;
      cursor: pointer;
      font-size: 16px;
      // height: 45px;
      font-weight: 300;
      color: white;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      border-bottom: 2px solid rgba(255,255,255,.4);
      transition: all .3s ease-in-out;
      margin-bottom: 1rem;
      &:focus{
          border: none;
          outline: none;
          border-bottom: 2px solid white;
      }
   }
  }

  [type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 18px;
  display: inline-block;
  color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 4px;
  width: 16px;
  height: 16px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #393939;
  position: absolute;
  top: 6px;
  left: 2px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.buttonwithtext {
  display: grid;
  justify-items: center;
  grid-template-columns: auto auto;

  .sucess-msg{
      margin-right: 20px;
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
      line-height: 40px;
      font-size: 14px;
      align-content: center;
  }
  .err-msg{
   margin-right: 20px;
   margin-top: 0;
   margin-bottom: 0;
   padding: 0;
   line-height: 20px;
   font-size: 14px;
   align-content: center;
   color: rgb(255, 83, 83);
  }
}
}
  

  
  