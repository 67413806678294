.Insta {
    width: 427px;
    height: 542px;
    position: relative;
    justify-content: center;
    /* align-content: center; */
    /* overflow: hidden; */
    border-radius: 0px;
    box-shadow: 0 20px 40px rgba(0,0,0, 0.25);
    display: grid;
    /* grid-template-rows: 1tr; */
    grid-template-rows: 200px 180px auto;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}

.Insta:hover .Instaimage {
    opacity: 0.3;
    transform: scale(1.1, 1.1);
    box-shadow: 0 30px 60px rgba(0,0,0, 0.5)
}

.Instaimage:hover {
    transform: translateY(-20px);
}

.Instaimage {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: -1;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}

.Instalogo {
    opacity: 0;
    margin-top: 128px;
    margin-bottom: 0px;
    /* position: absolute; */
    height: 45px;
    width: 100%;
    /* z-index: 1; */
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}

.Insta1 {
    opacity: 0;
    margin: 0px;
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 17px;
    line-height: 25px;
    font-weight: 300;
    width: 320px;
    height: 100%;
    /* margin: 0 0 20px 20px; */
}

.Insta2 {
    opacity: 0;
    top: 0px;
    text-align: center;
    vertical-align: top;
    color: rgba(255, 255, 255, 1);
    /* text-transform: uppercase; */
    font-weight: 400;
    font-size: 17px;
    margin: 0px;
}

.Insta:hover .Instalogo {
    /* color: rgba(255,255,255, 0.8); */
    animation: HeroAnimation;
    animation-duration: 3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
}

.Insta:hover .Insta1 {
    /* color: rgba(255,255,255, 1); */
    animation: HeroAnimation;
    animation-duration: 3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
}

.Insta:hover .Insta2 {
    /* color: rgba(255,255,255, 1); */
    animation: HeroAnimation;
    animation-duration: 3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
}

@media (max-width: 1699px) {
    .Insta {
        width: 323px;
        height: 410px;
        grid-template-rows: 160px 180px auto;
    }
    .Instalogo {
        opacity: 0;
        margin-top: 60px;
        margin-bottom: 0px;
        /* position: absolute; */
        height: 45px;
        width: 100%;
        /* z-index: 1; */
        transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
    }
    .Insta1 {
        width: 280px;
    }
   }

   @media (max-width: 1400px) {
    .Insta {
        width: 273px;
        height: 346px;
        grid-template-rows: 130px 160px auto;
    }
    .Instalogo {
        opacity: 0;
        margin-top: 40px;
        margin-bottom: 0px;
        /* position: absolute; */
        height: 45px;
        width: 100%;
        /* z-index: 1; */
        transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
    }
    .Insta1 {
        width: 273px;
    }
   }
   @media (max-width: 1199px) {
    .Insta {
        width: 244px;
        height: 310px;
        grid-template-rows: 110px 150px auto;
    }
    .Instalogo {
        opacity: 0;
        margin-top: 40px;
        margin-bottom: 0px;
        /* position: absolute; */
        height: 45px;
        width: 100%;
        /* z-index: 1; */
        transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
    }
    .Insta1 {
        width: 224px;
    }
   }
   @media (max-width: 969px) {
    .Insta {
        width: 236px;
        height: 300px;
        grid-template-rows: 100px 160px auto;
    }
    .Instalogo {
        opacity: 0;
        margin-top: 40px;
        margin-bottom: 0px;
        /* position: absolute; */
        height: 45px;
        width: 100%;
        /* z-index: 1; */
        transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
    }
    .Insta1 {
        width: 200px;
    }

.Instaimage {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}
   }

   @media (max-width: 819px) {
    .Insta {
        width: 230px;
        height: 300px;
        grid-template-rows: 100px 160px auto;
    }
    .Instalogo {
        opacity: 0;
        margin-top: 40px;
        margin-bottom: 0px;
        /* position: absolute; */
        height: 45px;
        width: 100%;
        /* z-index: 1; */
        transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
    }
    .Insta1 {
        width: 200px;
    }

.Instaimage {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}
   }

   @media (max-width: 749px) {
    .Insta {
        width: 100%;
        height: 100vw;
        grid-template-rows: 240px 160px auto;
    }
    .Instalogo {
        opacity: 0;
        margin-top: 150px;
        margin-bottom: 0px;
        /* position: absolute; */
        height: 45px;
        width: 100%;
        /* z-index: 1; */
        transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
    }
    .Insta1 {
        width: 200px;
    }

.Instaimage {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}
   }
   
   @media (max-width: 599px) {
    .Insta {
        width: 100%;
        height: 110vw;
        grid-template-rows: 240px 160px auto;
    }
    .Instalogo {
        opacity: 0;
        margin-top: 150px;
        margin-bottom: 0px;
        /* position: absolute; */
        height: 45px;
        width: 100%;
        /* z-index: 1; */
        transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
    }
    .Insta1 {
        width: 200px;
    }

.Instaimage {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}
   }
   
@keyframes HeroAnimation {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  @media (max-width: 359px) {
    .Insta {
        width: 100%;
        height: 110vw;
        grid-template-rows: 150px 160px auto;
    }
    .Instalogo {
        opacity: 0;
        margin-top: 100px;
        margin-bottom: 0px;
        /* position: absolute; */
        height: 45px;
        width: 100%;
        /* z-index: 1; */
        transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
    }
    .Insta1 {
        width: 200px;
    }

.Instaimage {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}
   }
   
@keyframes HeroAnimation {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }


  @media (max-width: 413px) {
    .Insta {
        width: 100%;
        height: 110vw;
        grid-template-rows: 120px 160px auto;
    }
    .Instalogo {
        opacity: 0;
        margin-top: 50px;
        margin-bottom: 0px;
        /* position: absolute; */
        height: 45px;
        width: 100%;
        /* z-index: 1; */
        transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
    }
    .Insta1 {
        width: 200px;
    }

.Instaimage {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1)
}
   }
   
@keyframes HeroAnimation {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }