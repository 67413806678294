
.cards-projects {
    margin-top: 100px;
    margin-bottom: 60px;
}

.project-title {
    margin: 0;
    margin-top: 20px;
    color: #ffffff;
    font-size: 46px;
    line-height: 52px;
    text-align: left;
    font-weight: 700;

}

.project-info-text {
    text-align: left;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    font-weight: 300;
    margin-bottom: 40px;

}

@media (max-width: 1799px) {
    .project-title {
        margin: 0;
        margin-top: 15px;
        color: #ffffff;
        font-size: 40px;
        line-height: 52px;
        text-align: left;
        font-weight: 700;
    }
    
    .project-info-text {
        text-align: left;
        font-size: 18px;
        line-height: 28px;
        color: #ffffff;
        font-weight: 300;
        margin-bottom: 30px;

    }
  }
  @media (max-width: 1400px) {
    .project-title {
        margin: 0;
        margin-top: 5px;
        color: #ffffff;
        font-size: 32px;
        line-height: 42px;
        text-align: left;
        font-weight: 700;
    }
    
    .project-info-text {
        text-align: left;
        font-size: 16px;
        line-height: 22px;
        color: #ffffff;
        font-weight: 300;
        margin-top: 12px;
        margin-bottom: 20px;

    }
  }

  @media (max-width: 1199px) {
    .project-title {
        margin: 0;
        margin-top: 5px;
        color: #ffffff;
        font-size: 32px;
        line-height: 42px;
        text-align: left;
        font-weight: 700;
    }
    
    .project-info-text {
        text-align: left;
        font-size: 16px;
        line-height: 22px;
        color: #ffffff;
        font-weight: 300;
        margin-top: 12px;
        margin-bottom: 20px;

    }
  }


/* .aboutme-brow {
    text-align: center;
    margin: 0;
    font-weight: 300;
    color: #C8C8C8;
    letter-spacing: 4px;
    font-size: 14px;
    line-height: 16px;
    opacity: 100;
    animation: HeroAnimation;
    animation-duration: 3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
    } */