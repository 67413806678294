.hero-container-aboutme {
    padding: 0 calc(50% - 700px);
    flex-direction: column;
}

.aboutme-texts {
    margin-top: 100px;
    margin-bottom: 60px;
    display: grid;
    justify-content: center;
    text-align: center;
}

.aboutme-brow {
margin: 0;
font-weight: 300;
color: #C8C8C8;
letter-spacing: 4px;
font-size: 14px;
line-height: 16px;
opacity: 100;
}

.aboutme-title {
    justify-self: center;
    width: 1200px;
    margin: 0;
    margin-top: 20px;
    color: #ffffff;
    font-size: 46px;
    line-height: 50px;
    font-weight: 700;
}

.aboutme-info-text {
    justify-self: center;
    width: 1000px;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    font-weight: 300;
    margin-bottom: 40px;
}

.aboutme-table {
    display: grid;
    grid-template-columns: 456px 488px 456px;
}

.profile-img {
    width: 488px;
    z-index: -1;
}

.text-table-title {
    margin-top: 150px;
    font-size: 16px;
    letter-spacing: 2px;
    font-weight: 300;
}

.text-table {
    font-size: 28px;
    line-height: 40px;
    font-weight: 600;
    letter-spacing: 2.5px;
    margin: 0;
    -webkit-transition: transform 0.5s ease-out;
    -moz-transition: transform 0.5s ease-out;
    -o-transition: transform 0.5s ease-out;
    transition: transform 0.5s ease-out;
}

.text-table:hover {
    /* font-size: 34px; */
    transform: scale(1.3);
}


.icons-table {
    margin-top: 30px;
    justify-content: center;
    display: grid;
    grid-template-columns: 80px 80px 80px;
    grid-template-rows: 80px 80px 80px; 
    column-gap: 10px;
    row-gap: 10px;
    justify-items: center;
    align-items: center;
}

.icons-image {
    -webkit-transition: transform 0.4s ease-out;
    -moz-transition: transform 0.4s ease-out;
    -o-transition: transform 0.4s ease-out;
    transition: transform 0.4s ease-out;
}

.icons-image:hover {
 /* width: 80px; */
 transform: scale(1.4);
}

@media (max-width: 1799px) {
   
    .aboutme-title {
        justify-self: center;
        width: 1200px;
        margin: 0;
        margin-top: 10px;
        color: #ffffff;
        font-size: 40px;
        line-height: 48px;
    }
    
    .aboutme-info-text {
        justify-self: center;
        width: 1000px;
        font-size: 20px;
        line-height: 28px;
        color: #ffffff;
        font-weight: 300;
        margin-bottom: 15px;
    }
  }

  @media (max-width: 1699px) {
    .hero-container-aboutme {
        padding: 0 calc(50% - 530px);
        flex-direction: column;
    }
    .profile-img {
        width: 440px;
    }
    .text-table-title {
        margin-top: 100px;
    }

    .aboutme-table {
        display: grid;
        grid-template-columns: 380px 488px 380px;
    }
    
    .aboutme-title {
        justify-self: center;
        width: 1200px;
        margin: 0;
        margin-top: 10px;
        color: #ffffff;
        font-size: 34px;
        line-height: 44px;
    }
    
    .aboutme-info-text {
        justify-self: center;
        width: 1000px;
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
        font-weight: 300;
        margin-bottom: 15px;
    }
  }

  @media (max-width: 1400px) {
    .hero-container-aboutme {
        padding: 0 calc(50% - 530px);
        flex-direction: column;
    }
    .profile-img {
        width: 380px;
    }
    .text-table-title {
        margin-top: 80px;
    }
    .aboutme-table {
        display: grid;
        grid-template-columns: 300px 380px 300px;
    }
    
    .aboutme-title {
        justify-self: center;
        width: 900px;
        margin: 0;
        margin-top: 10px;
        color: #ffffff;
        font-size: 30px;
        line-height: 40px;
    }
    
    .aboutme-info-text {
        justify-self: center;
        width: 900px;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        font-weight: 300;
        margin-bottom: 15px;
    }
    .text-table {
        font-size: 24px;
        line-height: 30px;
        font-weight: 600;
        letter-spacing: 2.5px;
        margin: 0;
        -webkit-transition: transform 0.5s ease-out;
        -moz-transition: transform 0.5s ease-out;
        -o-transition: transform 0.5s ease-out;
        transition: transform 0.5s ease-out;
    }
    
    .icons-table {
        margin-top: 30px;
        justify-content: center;
        display: grid;
        grid-template-columns: 60px 60px 60px;
        grid-template-rows: 60px 60px 60px; 
        column-gap: 10px;
        row-gap: 10px;
    }
    .icons-image {
    width: 50px;
    }
    .aboutme-brow {
        margin: 0;
        font-weight: 300;
        color: #C8C8C8;
        letter-spacing: 3px;
        line-height: 14px;
        }
  }


  @media (max-width: 1199px) {
    .hero-container-aboutme {
        padding: 0 calc(50% - 530px);
        flex-direction: column;
    }
    .profile-img {
        width: 320px;
    }
    .text-table-title {
        margin-top: 80px;
    }
    .aboutme-table {
        display: grid;
        grid-template-columns: 300px 320px 300px;
    }
    
    .aboutme-title {
        justify-self: center;
        width: 900px;
        margin: 0;
        margin-top: 10px;
        color: #ffffff;
        font-size: 24px;
        line-height: 34px;
    }
    
    .aboutme-info-text {
        justify-self: center;
        width: 800px;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        font-weight: 300;
        margin-bottom: 15px;
        margin-top: 10px;
    }
    .text-table {
        font-size: 24px;
        line-height: 30px;
        font-weight: 600;
        letter-spacing: 2.5px;
        margin: 0;
        -webkit-transition: transform 0.5s ease-out;
        -moz-transition: transform 0.5s ease-out;
        -o-transition: transform 0.5s ease-out;
        transition: transform 0.5s ease-out;
    }
    
    .icons-table {
        margin-top: 30px;
        justify-content: center;
        display: grid;
        grid-template-columns: 60px 60px 60px;
        grid-template-rows: 60px 60px 60px; 
        column-gap: 10px;
        row-gap: 10px;
    }
    .icons-image {
    width: 50px;
    }
    .aboutme-brow {
        margin: 0;
        font-weight: 300;
        color: #C8C8C8;
        letter-spacing: 2px;
        font-size: 12px;
        line-height: 14px;
        }
  }

  @media (max-width: 969px) {
    .hero-container-aboutme {
        padding: 0 calc(50% - 360px);
        flex-direction: column;
    }
    .profile-img {
        width: 260px;
    }
    .text-table-title {
        font-size: 14px;
        margin-top: 50px;
    }
    .aboutme-table {
        display: grid;
        grid-template-columns: 250px 260px 250px;
    }
    
    .aboutme-title {
        justify-self: center;
        width: 760px;
        margin: 0;
        margin-top: 10px;
        color: #ffffff;
        font-size: 24px;
        line-height: 34px;
    }
    
    .aboutme-info-text {
        justify-self: center;
        width: 760px;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        font-weight: 300;
        margin-bottom: 15px;
        margin-top: 10px;
    }
    .text-table {
        margin-top: 20px;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
        letter-spacing: 2.5px;
        margin: 0;
        -webkit-transition: transform 0.5s ease-out;
        -moz-transition: transform 0.5s ease-out;
        -o-transition: transform 0.5s ease-out;
        transition: transform 0.5s ease-out;
    }
    .icons-table {
        margin-top: 10px;
        justify-content: center;
        display: grid;
        grid-template-columns: 60px 60px 60px;
        grid-template-rows: 60px 60px 60px; 
        column-gap: 8px;
        row-gap: 8px;
    }
    .icons-image {
    width: 46px;
    }
    .aboutme-brow {
        margin: 0;
        font-weight: 300;
        color: #C8C8C8;
        letter-spacing: 2px;
        font-size: 12px;
        line-height: 14px;
        }
  }

  @media (max-width: 819px) {
    .hero-container-aboutme {
        padding: 0 calc(50% - 360px);
        flex-direction: column;
    }
    .profile-img {
        width: 260px;
    }
    .text-table-title {
        font-size: 14px;
        margin-top: 50px;
    }
    .aboutme-table {
        display: grid;
        grid-template-columns: 250px 260px 250px;
        
    }
    
    .aboutme-title {
        justify-self: center;
        width: 720px;
        margin: 0;
        margin-top: 10px;
        color: #ffffff;
        font-size: 24px;
        line-height: 34px;
    }
    
    .aboutme-info-text {
        justify-self: center;
        width: 720px;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        font-weight: 300;
        margin-bottom: 15px;
        margin-top: 10px;
    }
    .text-table {
        margin-top: 20px;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
        letter-spacing: 2.5px;
        margin: 0;
        -webkit-transition: transform 0.5s ease-out;
        -moz-transition: transform 0.5s ease-out;
        -o-transition: transform 0.5s ease-out;
        transition: transform 0.5s ease-out;
    }
    .icons-table {
        margin-top: 10px;
        justify-content: center;
        display: grid;
        grid-template-columns: 60px 60px 60px;
        grid-template-rows: 60px 60px 60px; 
        column-gap: 8px;
        row-gap: 8px;
    }
    .icons-image {
    width: 46px;
    }
    .aboutme-brow {
        margin: 0;
        font-weight: 300;
        color: #C8C8C8;
        letter-spacing: 2px;
        font-size: 12px;
        line-height: 14px;
        }
  }

  @media (max-width: 749px) {
    .hero-container-aboutme {
        padding: 0 calc(50% - 360px);
        flex-direction: column;
    }
    .profile-img {
        width: 260px;
        justify-self: center;
    }
    .text-table-title {
        font-size: 14px;
        margin-top: 50px;
    }
    .aboutme-table {
        display: grid;
        grid-template-columns: 250px 260px 250px;
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 50px;
    }
    
    .aboutme-title {
        justify-self: center;
        width: 560px;
        margin: 0;
        margin-top: 0px;
        color: #ffffff;
        font-size: 24px;
        line-height: 34px;
    }
    
    .aboutme-info-text {
        justify-self: center;
        width: 560px;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        font-weight: 300;
        margin-bottom: 15px;
        margin-top: 10px;
    }
    .text-table {
        margin-top: 0px;
        font-size: 24px;
        line-height: 34px;
        font-weight: 600;
        letter-spacing: 2.5px;
        margin: 0;
        -webkit-transition: transform 0.5s ease-out;
        -moz-transition: transform 0.5s ease-out;
        -o-transition: transform 0.5s ease-out;
        transition: transform 0.5s ease-out;
    }
    .icons-table {
        margin-top: 10px;
        justify-content: center;
        display: grid;
        grid-template-columns: 65px 65px 65px;
        grid-template-rows: 65px 65px 65px; 
        column-gap: 10px;
        row-gap: 10px;
        justify-items: center;
        align-items: center;
    }
    .icons-image {
    width: 50px;
    }
    .aboutme-brow {
        margin: 0;
        font-weight: 300;
        color: #C8C8C8;
        letter-spacing: 2px;
        font-size: 12px;
        line-height: 14px;
        }
        .text-table-title {
            margin-top: 20px;
            font-size: 16px;
            letter-spacing: 2px;
            font-weight: 300;
        }
  }


  @media (max-width: 599px) {
    .hero-container-aboutme {
        max-width: 100%;
        margin: auto;
        padding: 0 20px 0 20px;
        flex-direction: column;
    }
    .profile-img {
        width: 260px;
        justify-self: center;
    }
    .text-table-title {
        font-size: 14px;
        margin-top: 50px;
    }
    .aboutme-table {
        display: grid;
        grid-template-columns: 250px 260px 250px;
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 50px;
    }
    
    .aboutme-title {
        justify-self: center;
        width: 100%;
        margin: 0;
        margin-top: 0px;
        color: #ffffff;
        font-size: 24px;
        line-height: 34px;
    }
    
    .aboutme-info-text {
        justify-self: center;
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        font-weight: 300;
        margin-bottom: 15px;
        margin-top: 10px;
    }
    .text-table {
        margin-top: 0px;
        font-size: 24px;
        line-height: 34px;
        font-weight: 600;
        letter-spacing: 2.5px;
        margin: 0;
        -webkit-transition: transform 0.5s ease-out;
        -moz-transition: transform 0.5s ease-out;
        -o-transition: transform 0.5s ease-out;
        transition: transform 0.5s ease-out;
    }
    .icons-table {
        margin-top: 10px;
        justify-content: center;
        display: grid;
        grid-template-columns: 65px 65px 65px;
        grid-template-rows: 65px 65px 65px; 
        column-gap: 10px;
        row-gap: 10px;
        justify-items: center;
        align-items: center;
    }
    .icons-image {
    width: 50px;
    }
    .aboutme-brow {
        margin: 0;
        font-weight: 300;
        color: #C8C8C8;
        letter-spacing: 2px;
        font-size: 12px;
        line-height: 14px;
        }
        .text-table-title {
            margin-top: 20px;
            font-size: 16px;
            letter-spacing: 2px;
            font-weight: 300;
        }
  }


  @media (max-width: 413px) {
    .hero-container-aboutme {
        max-width: 100%;
      margin: auto;
      padding: 0 20px 0 20px;
        flex-direction: column;
    }
    .profile-img {
        width: 260px;
        justify-self: center;
    }
    .text-table-title {
        font-size: 14px;
        margin-top: 50px;
    }
    .aboutme-table {
        display: grid;
        grid-template-columns: 250px 260px 250px;
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 50px;
    }
    
    .aboutme-title {
        justify-self: center;
        width: 100%;
        margin: 0;
        margin-top: 0px;
        color: #ffffff;
        font-size: 22px;
        line-height: 30px;
    }
    
    .aboutme-info-text {
        justify-self: center;
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        font-weight: 300;
        margin-bottom: 15px;
        margin-top: 10px;
    }
    .text-table {
        margin-top: 0px;
        font-size: 24px;
        line-height: 34px;
        font-weight: 600;
        letter-spacing: 2.5px;
        margin: 0;
        -webkit-transition: transform 0.5s ease-out;
        -moz-transition: transform 0.5s ease-out;
        -o-transition: transform 0.5s ease-out;
        transition: transform 0.5s ease-out;
    }
    .icons-table {
        margin-top: 10px;
        justify-content: center;
        display: grid;
        grid-template-columns: 65px 65px 65px;
        grid-template-rows: 65px 65px 65px; 
        column-gap: 10px;
        row-gap: 10px;
        justify-items: center;
        align-items: center;
    }
    .icons-image {
    width: 50px;
    }
    .aboutme-brow {
        margin: 0;
        font-weight: 300;
        color: #C8C8C8;
        letter-spacing: 2px;
        font-size: 12px;
        line-height: 14px;
        }
        .text-table-title {
            margin-top: 20px;
            font-size: 16px;
            letter-spacing: 2px;
            font-weight: 300;
        }
  }