.main-footer {
    background-color: black;
    color: white;
    padding-top: 3em;
    bottom: 0;
    width: 100%;
    z-index: 1;
    padding: 0 calc(50% - 700px);
    padding-top: 20px;
}

.footer-text {
    font-size: 18px;
    line-height: 26px;
}
.row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 90px;
}

.row2 {
   padding-top: 20px;
   padding-left: 0;
}

.col1 {
    padding: 0;
}
/* 
.row-h4-4 {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 1px;
} */

.row-h4-4 {
    letter-spacing: 2px;
    margin-top: 0px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 300;
    margin-bottom: 9px;
}

.list-unstyled-1-1 {
    font-size: 17px;
    color: #888A8E;
    list-style: none;
    padding: 0;
    width: 405px;
    font-weight: 300;
    line-height: 24px;
}

.span1 {
    color: #888A8E;
}

.contact-1 {
    color: #ffffff;
}

.col-sm {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: #888A8E;
    width: 200px;
    margin-left: 10px;
}

.list-unstyled-2-2 {
    font-size: 17px;
    color: #ffffff;
    list-style: none;
    padding: 0;
    font-weight: 300;
    line-height: 24px;
}

.list-unstyled-3-3 {
    font-size: 17px;
    color: #888A8E;
    list-style: none;
    padding: 0;
    font-weight: 300;
    line-height: 24px;
}

.list-unstyled-4-4 {
    font-size: 17px;
    color: #ffffff;
    list-style: none;
    padding: 0;
    font-weight: 300;
    line-height: 24px;
}

.footer-link {
    color: #ffffff;
    line-height: 26px;
    text-decoration: none;
}

@media (max-width: 1699px) {

    .main-footer {
        margin-top: 20px;
        padding: 0 calc(50% - 530px);
        
    }
    .row {
        display: grid;
        grid-template-columns: 300px auto auto auto;
        grid-gap: 60px;
    }
    .list-unstyled-1-1 {
        font-size: 17px;
        color: #888A8E;
        list-style: none;
        padding: 0;
        width: 300px;
        font-weight: 300;
        line-height: 24px;
    }
  }

  @media (max-width: 1400px) {
    .main-footer {
        margin-top: 20px;
        padding: 0 calc(50% - 450px);
        
    }
    .row {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 50px;
    }
    .col-1 {
        width: 200px;
        margin-right: 50px;
    }
    .col-2 {
        width: 150px;
    }
    .col-3 {
        width: 180px;
    }
    .col-4 { 
        width: 130px;
    }
    .list-unstyled-1-1 {
        width: 250px;
        line-height: 24px;
    }
    .list-unstyled-2-2 {
        width: 150px;
    }
    .list-unstyled-3-3 {
        width: 150px;
    }
    .list-unstyled-4-4 {
        width: 100px;
    }
    
    .row-h4-4 {
     letter-spacing: 1px;
    }
  }

  @media (max-width: 1199px) {
    .footer-text {
        font-size: 16px;
        line-height: 26px;
    }

    .main-footer {
        margin-top: 20px;
        padding: 0 calc(50% - 400px);
    }
    .row {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 40px;
    }
    .col-1 {
        width: 220px;
        margin-right: 50px;
    }
    .col-2 {
        width: 120px;
    }
    .col-3 {
        width: 180px;
    }
    .col-4 { 
        width: 80px;
    }
    .list-unstyled-1-1 {
        width: 250px;
        line-height: 24px;
    }
    .list-unstyled-2-2 {
        width: 150px;
    }
    .list-unstyled-3-3 {
        width: 180px;
    }
    .list-unstyled-4-4 {
        width: 120px;
    }
    
    .row-h4-4 {
     letter-spacing: 1px;
     line-height: 22px;
     width: 120px;
    }
  }

  @media (max-width: 969px) {
    .main-footer {
        margin-top: 20px;
        padding: 0 calc(50% - 380px);
        
    }
    .row {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 40px;
        
    }
    .col-1 {
        width: 200px;
        margin-right: 50px;
    }
    .col-2 {
        width: 120px;
    }
    .col-3 {
        width: 180px;
    }
    .col-4 { 
        width: 80px;
    }
    .list-unstyled-1-1 {
        width: 250px;
        line-height: 24px;
    }
    .list-unstyled-2-2 {
        width: 150px;
    }
    .list-unstyled-3-3 {
        width: 180px;
    }
    .list-unstyled-4-4 {
        width: 120px;
    }
    
    .row-h4-4 {
     letter-spacing: 1px;
     line-height: 22px;
     width: 120px;
    }
  }

  @media (max-width: 819px) {
    .main-footer {
        margin-top: 20px;
        padding: 0 calc(50% - 360px);
        
    }
    .row {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 30px;
    }
    .col-1 {
        width: 200px;
        margin-right: 50px;
    }
    .col-2 {
        width: 120px;
    }
    .col-3 {
        width: 150px;
    }
    .col-4 { 
        width: 80px;
    }
    .list-unstyled-1-1 {
        width: 250px;
        line-height: 24px;
    }
    .list-unstyled-2-2 {
        width: 150px;
    }
    .list-unstyled-3-3 {
        width: 150px;
    }
    .list-unstyled-4-4 {
        width: 120px;
    }
    
    .row-h4-4 {
     letter-spacing: 1px;
     line-height: 22px;
     width: 120px;
    }
  }

  /* @media (max-width: 749px) {
    .main-footer {
        max-width: 100%;
        margin: auto;
        padding: 0 18% 0 18%;
    }
    .row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
    }
    .col-1 {
        width: 200px;
        margin-right: 50px;
    }
    .col-2 {
        width: 120px;
    }
    .col-3 {
        width: 150px;
    }
    .col-4 { 
        width: 80px;
    }
    .list-unstyled-1-1 {
        width: 250px;
        line-height: 24px;
    }
    .list-unstyled-2-2 {
        width: 150px;
    }
    .list-unstyled-3-3 {
        width: 150px;
    }
    .list-unstyled-4-4 {
        width: 120px;
    }
    
    .row-h4-4 {
     letter-spacing: 1px;
     line-height: 22px;
     width: 120px;
    }
  } */

  @media (max-width: 749px) {
    .main-footer {
        max-width: 100%;
        margin: auto;
        padding-top: 20px;
        display: grid;
        justify-content: center;
    }
    .row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
        width: 400px;
    }
    .col-1 {
        width: 200px;
        margin-right: 50px;
    }
    .col-2 {
        width: 120px;
    }
    .col-3 {
        width: 150px;
    }
    .col-4 { 
        width: 80px;
    }
    .list-unstyled-1-1 {
        width: 250px;
        line-height: 24px;
    }
    .list-unstyled-2-2 {
        width: 150px;
    }
    .list-unstyled-3-3 {
        width: 150px;
    }
    .list-unstyled-4-4 {
        width: 120px;
    }
    
    .row-h4-4 {
     letter-spacing: 1px;
     line-height: 22px;
     width: 120px;
    }
  }

  @media (max-width: 599px) {
    .main-footer {
        max-width: 100%;
        margin: auto;
        padding-top: 20px;
        display: grid;
        justify-content: center;
    }
    .row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 22px;
        width: 360px;
    }
    .col-1 {
        width: 200px;
        margin: 0;
        margin-right: 15px;
    }
    .col-2 {
        width: 100px;
    }
    .col-3 {
        width: 150px;
    }
    .col-4 { 
        width: 80px;
    }
    .list-unstyled-1-1 {
        width: 200px;
        line-height: 24px;
    }
    .list-unstyled-2-2 {
        width: 150px;
    }
    .list-unstyled-3-3 {
        width: 150px;
    }
    .list-unstyled-4-4 {
        width: 120px;
    }
    
    .row-h4-4 {
     letter-spacing: 1px;
     line-height: 22px;
     width: 120px;
    }
  }

  @media (max-width: 413px) {
    .main-footer {
        max-width: 100%;
        margin: auto;
        padding: 20px 20px 0 20px;
        display: grid;
        justify-content: center;
    }
    .row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 22px;
        width: 335px;
    }
    .col-1 {
        width: 200px;
        margin: 0;
        margin-right: 0px;
    }
    .col-2 {
        width: 100px;
    }
    .col-3 {
        width: 150px;
    }
    .col-4 { 
        width: 80px;
    }
    .list-unstyled-1-1 {
        width: 180px;
        line-height: 24px;
    }
    .list-unstyled-2-2 {
        width: 100px;
    }
    .list-unstyled-3-3 {
        width: 180px;
    }
    .list-unstyled-4-4 {
        width: 120px;
    }
    
    .row-h4-4 {
     letter-spacing: 1px;
     line-height: 22px;
     width: 120px;
    }
  }

  @media (max-width: 374px) {
    .main-footer {
        max-width: 100%;
        margin: auto;
        padding: 20px 20px 0 20px;
        display: grid;
        justify-content: center;
    }
    .row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
        width: 320px;
    }
    .col-1 {
        width: 200px;
        margin: 0;
        margin-right: 0px;
    }
    .col-2 {
        width: 100px;
    }
    .col-3 {
        width: 150px;
    }
    .col-4 { 
        width: 80px;
    }
    .list-unstyled-1-1 {
        width: 180px;
        line-height: 24px;
    }
    .list-unstyled-2-2 {
        width: 100px;
    }
    .list-unstyled-3-3 {
        width: 180px;
    }
    .list-unstyled-4-4 {
        width: 120px;
    }
    
    .row-h4-4 {
     letter-spacing: 1px;
     line-height: 22px;
     width: 120px;
    }
  }

  @media (max-width: 359px) {
    .main-footer {
        max-width: 100%;
        margin: auto;
        padding: 20px 20px 0 20px;
        display: grid;
        justify-content: center;
    }
    .row {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 15px;
        width: 280px;
    }
    .col-1 {
        width: 200px;
        margin: 0;
        margin-right: 0px;
    }
    .col-2 {
        width: 100px;
    }
    .col-3 {
        width: 150px;
    }
    .col-4 { 
        width: 80px;
    }
    .list-unstyled-1-1 {
        width: 260px;
        line-height: 24px;
    }
    .list-unstyled-2-2 {
        width: 100px;
    }
    .list-unstyled-3-3 {
        width: 260px;
    }
    .list-unstyled-4-4 {
        width: 120px;
    }
    
    .row-h4-4 {
     letter-spacing: 1px;
     line-height: 22px;
     width: 120px;
    }
  }