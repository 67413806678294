.Header {
    background: transparent;
    /* height: 200px; */
    position: fixed;
    z-index: 2;
    width: 100%;
    top: 0;
}
 
.Header.active {
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);
    /* opacity: 50%; */
    height: 120px;
    z-index: 2; 
}

.HeaderGroup {
    top: 40px;
    left: 38px;
    z-index: 3; 
    position: fixed;
    height: 120px;
    width: 100%;
}

.post {
    bottom: 40px;
    left: 38px;
    position: fixed;
}

.post-logo {
    width: 223px;
    height: 46px;
}

.logo-home {
     top: 0px;
    left: 38px;
    fill: #ffffff;
}

.svg {
    left: 38px;
    fill: #ffffff;
}

.logo {
    left: 38px;
    fill: #000000;
}
.button_back:hover .thisisImage {
    color: white;
}

.post:hover .svg {
    color: black;
}


@media (min-width: 1921px) {
    .HeaderGroup {
        top: 40px;
        left: 38px;
        z-index: 3; 
        position: fixed;
        height: 120px;
        width: 100%;
    }
    .Header.active {
        background: rgba(0, 0, 0, 0);
        backdrop-filter: blur(5px);
        height: 0px;
        z-index: 2; 
    }
}

@media (max-width: 1970px) and (min-width: 1919px) {
    .HeaderGroup {
        top: 40px;
        left: 15px;
        z-index: 3; 
        position: fixed;
        height: 120px;
        width: 100%;
    }
    .Header.active {
        background: rgba(0, 0, 0, 0);
        backdrop-filter: blur(5px);
        height: 0px;
        z-index: 2; 
    }
}

@media (max-width: 1919px) {
    .HeaderGroup {
        top: 40px;
        z-index: 3; 
        position: fixed;
        height: 120px;
        width: 100%;
    }
    .Header.active {
        z-index: 2; 
    }
}
@media (max-width: 1699px) and (min-width: 1630px) {
    .HeaderGroup {
        top: 40px;
        z-index: 3; 
        position: fixed;
        height: 120px;
        width: 100%;
    }
    .Header.active {
        background: rgba(0, 0, 0, 0);
        backdrop-filter: blur(5px);
        height: 0px;
        z-index: 2; 
    }
}
@media (max-width: 1629px) {
    .HeaderGroup {
        top: 20px;
        z-index: 3; 
        position: fixed;
        height: 120px;
        width: 100%;
    }
    .Header.active {
        z-index: 2; 
        height: 85px;
    }
}

@media (max-width: 1800px) {
    .post {
    left: 0px;
    padding-left: 0px;
    margin-left: 0px;
    }
  }

  @media (max-width: 1400px) {
    .post-logo {
        width: 120px;
        height: 40px;
    }
    .logo-home {
       width: 190px;
   }
  }

  @media (max-width: 969px) {
    .post-logo {
        width: 0px;
        height: 0px;
    }
    .logo-home {
       width: 190px;
   }
  }

  @media (max-width: 749px) {
    .HeaderGroup {
        left: 60px;
   }
  }

  @media (max-width: 599px) {
    .HeaderGroup {
        left: 20px;
   }
  }